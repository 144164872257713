<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>案件一覧</el-breadcrumb-item>
      <el-breadcrumb-item v-if="this.$route.query.id == null || this.$route.query.status == 2">{{$t('addCustomer.title')}}</el-breadcrumb-item>
      <el-breadcrumb-item v-if="this.$route.query.id != null && this.$route.query.status == 1">{{$t('addCustomer.title2')}}</el-breadcrumb-item>
      <el-breadcrumb-item v-if="this.$route.query.id != null && this.$route.query.status == 4">案件確認</el-breadcrumb-item>
      <el-breadcrumb-item v-if="this.$route.query.id != null && this.$route.query.status == 5">案件回復</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span style="font-weight: bold;font-size:16px;" v-if="this.$route.query.id == null || this.$route.query.status == 2">{{$t('addCustomer.title')}}</span>
        <span style="font-weight: bold;font-size:16px;" v-if="this.$route.query.id != null && this.$route.query.status == 1">{{$t('addCustomer.title2')}}</span>
        <span style="font-weight: bold;font-size:16px;" v-if="this.$route.query.id != null && this.$route.query.status == 4">案件確認</span>
        <span style="font-weight: bold;font-size:16px;" v-if="this.$route.query.id != null && this.$route.query.status == 5">案件回復</span>
      </div>
      <el-form ref="form" :model="form" label-width="125px" :rules="rules" class="pp-customer">
        <!--顧客-->
        <el-divider content-position="left">
          <el-badge value="必須">
            <span class="sub-title">{{$t('addCustomer.customer')}}</span>
          </el-badge>
        </el-divider>
        <el-row>
          <el-col :span="6" v-show="this.accountInfo.role <= 3">
            <el-form-item :label="$t('addCustomer.customerId')" prop="clclid">
              <el-input size="small" v-model="form.clclid" placeholder="顧客ID" clearable style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="CompNo" prop="clcomp">
              <el-input size="small" v-model="form.clcomp" style="width: 90%;" :disabled="this.$route.query.status == 3" @blur="chkCompNo"></el-input>
              <!-- <el-input size="small" v-model="form.clcomp" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button size="small" type="success" class="pp-btn" icon="el-icon-search" plain round @click="searchCustomer" :disabled="this.$route.query.status == 3">{{$t('addCustomer.search')}}</el-button>
            <el-switch v-model="form.clstcd" :active-value=0 :inactive-value=1 :active-text="$t('addCustomer.add')" @change="addCustomerFlg" :disabled="this.$route.query.status == 3" style="margin-left: 28px"></el-switch>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.locationName')" prop="cllcid" v-if="this.accountInfo.role <= 3">
              <el-select size="small" v-model="form.cllcid" style="width: 90%;" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in locationList"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label=" " v-else>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item :label="$t('addCustomer.zipCode')" prop="clzipc">
              <el-input size="small" v-model="form.clzipc" style="width: 95%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-button size="small" class="pp-btn" icon="el-icon-search" style="width:60%;min-width:75px;" round @click="searchClZipCd" :disabled="this.$route.query.status == 3">{{$t('addCustomer.search')}}</el-button>
          </el-col>
          <el-col :span="6">
            <el-form-item label="担当者" prop="clmnkj">
              <el-input size="small" v-model="form.clmnkj" placeholder="漢字" style="width: 90%;" @blur="handleChangeClmnkj" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="媒体名" prop="clbdnm">
              <el-input size="small" v-model="form.clbdnm" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="本社名" prop="clnmkj">
              <el-input size="small" v-model="form.clnmkj" placeholder="漢字" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.prefecture')" prop="cladkj1">
              <el-input size="small" v-model="form.cladkj1" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="担当者フリガナ" prop="clmnkn" key="clmnkn1" v-if="this.form.clbdnm == 'マイナビ転職'">
              <el-input size="small" v-model.trim="form.clmnkn" placeholder="フリカナ" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
            <el-form-item label="担当者フリガナ" prop="clmnkn" key="clmnkn2" :rules="{ pattern: /[^\x01-\x7E]$/, message: '全角文字で入力してください。', trigger: 'blur' }" v-else>
              <el-input size="small" v-model.trim="form.clmnkn" placeholder="フリカナ" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.managementName')" prop="clmskj">
              <el-input size="small" v-model="form.clmskj" placeholder="漢字" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item :label="$t('addCustomer.managementId')" prop="clmnid">
              <el-input size="small" v-model="form.clmnid" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="本社名" prop="clnmkn">
              <el-input size="small" v-model="form.clnmkn" placeholder="フリカナ" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="市区町村" prop="cladkj2">
              <el-input size="small" v-model="form.cladkj2" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.post')" prop="clmnps">
              <el-input size="small" v-model="form.clmnps" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
          </el-col> 
          <!-- <el-col :span="6">
            <el-form-item :label="$t('addCustomer.managementName')" prop="clmskn">
              <el-input size="small" v-model="form.clmskn" placeholder="フリカナ" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="6">
            <el-form-item :label="$t('addCustomer.managementPw')" prop="clmnpw" label-width="105px">
              <el-input size="small" v-model="form.clmnpw" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row :span="3">
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.comment')" prop="clcmmt">
              <el-input type="textarea" size="small" v-model="form.clcmmt" :rows="7" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-row>
              <el-col :span="8">
                <el-form-item label="番地・建物名以下" prop="cladkj3" label-width="140px">
                  <el-input size="small" v-model.trim="form.cladkj3" style="width: 89%;" :disabled="this.$route.query.status == 3"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="代表者名" prop="clickj">
                  <el-input size="small" v-model="form.clickj" placeholder="漢字" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('addCustomer.tel')" prop="clttel">
                  <el-input size="small" v-model="form.clttel" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="代表者名" prop="clickn">
                  <el-input size="small" v-model="form.clickn" placeholder="フリカナ" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('addCustomer.fax')" prop="cltfax">
                  <el-input size="small" v-model="form.cltfax" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('addCustomer.post')" prop="clicps">
                  <el-input size="small" v-model="form.clicps" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <!--アンケート-->
        <el-divider content-position="left" v-if="accountInfo.role <= 3">
          <span class="sub-title">{{$t('addCustomer.questionnaire')}}</span>
        </el-divider>
        <el-row v-if="accountInfo.role <= 3">
          <el-col :span="6">
            <el-form-item label="メアド">
              <el-input size="small" v-model="form.clplm1" style="width: 90%;" @blur="handleChangeClplm1" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="担当者名" prop="clplm2">
              <el-input size="small" v-model="form.clplm2" style="width: 90%;" :disabled="this.form.clplm3 == 1 || this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.send')" prop="clplm3">
              <el-select size="small" v-model="form.clplm3" style="width: 90%;" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in sendOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="(accountInfo.role > 3 && form.gropid == null ) || form.gropid > 3">
            <el-form-item :label="$t('addCustomer.memo')">
              <el-input size="small" v-model="form.clplm4" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!--請求先-->
        <el-divider content-position="left" v-if="this.accountInfo.role <= 3">
          <el-badge value="必須">
            <span class="sub-title">{{$t('addCustomer.payee')}}</span>
          </el-badge>
        </el-divider>
        <el-row v-if="this.accountInfo.role <= 3">
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.payeeId')" prop="blcbid">
              <el-input size="small" v-model="form.blcbid" placeholder="請求先ID" clearable style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-button size="small" type="success" class="pp-btn" icon="el-icon-search" plain round @click="searchPayee" :disabled="this.$route.query.status == 3">{{$t('addCustomer.search')}}</el-button>
            <el-switch v-model="form.blstcd" active-value="0" inactive-value="1" :active-text="$t('addCustomer.add')" @change="addPayeeFlag" :disabled="this.$route.query.status == 3" style="margin-left: 28px"></el-switch>
          </el-col>
        </el-row>
        <el-row :span="3" v-if="this.accountInfo.role <= 3">
          <el-col :span="18">
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('addCustomer.payeeNm')" prop="blnmkj">
                  <!-- <el-input size="small" v-model="form.blnmkj" placeholder="漢字" style="width: 90%;" @change="handleInitPrintMode" :disabled="this.$route.query.status == 3"></el-input> -->
                  <el-input size="small" v-model="form.blnmkj" placeholder="漢字" style="width: 90%;" @blur="handleChangeBlnmkj" :disabled="this.$route.query.status == 3"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="$t('addCustomer.zipCode')" prop="blzipc">
                  <el-input size="small" v-model="form.blzipc" style="width: 95%;" :disabled="this.$route.query.status == 3"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <el-button size="small" class="pp-btn" style="width:60%;min-width:75px;" icon="el-icon-search" round @click="searchBlZipCd" :disabled="this.$route.query.status == 3">{{$t('addCustomer.search')}}</el-button>
              </el-col>
              <!-- <el-col :span="6">
                <el-form-item :label="$t('addCustomer.tel')" prop="blltel">
                  <el-input size="small" v-model="form.blltel" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
                </el-form-item>
              </el-col> -->
              <el-col :span="8">
                <el-form-item :label="$t('addCustomer.transferFee')" prop="bltflg">
                  <el-select size="small" v-model="form.bltflg" style="width: 90%;" :disabled="this.form.payeeMode == 1 || this.$route.query.status == 3">
                    <el-option
                      v-for="item in transferFeeOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('addCustomer.payeeNm')" prop="blnmkn">
                  <el-input size="small" v-model="form.blnmkn" placeholder="フリカナ" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('addCustomer.deAddress')" prop="blakj1">
                  <el-input size="small" v-model="form.blakj1" placeholder="漢字" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="6">
                <el-form-item :label="$t('addCustomer.fax')" prop="bllfax">
                  <el-input size="small" v-model="form.bllfax" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
                </el-form-item>
              </el-col> -->
              <el-col :span="8">
                <el-form-item :label="$t('addCustomer.payment')" prop="pmtFlg">
                  <el-select size="small" v-model="form.pmtFlg" style="width: 90%;" :disabled="this.$route.query.status == 3">
                    <el-option
                      v-for="item in paymentOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('addCustomer.address3')" prop="recipientName">
                  <!-- <el-input size="small" v-model="form.recipientName" style="width: 90%;" @change="handleInitPrintMode" :disabled="this.$route.query.status == 3"></el-input> -->
                  <el-input size="small" v-model="form.recipientName" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('addCustomer.buildName')">
                  <el-input size="small" v-model="form.blakj2" placeholder="漢字" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="6">
                <el-form-item :label="$t('addCustomer.comment')" prop="blcmmt">
                  <el-input size="small" v-model="form.blcmmt" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
                </el-form-item>
              </el-col> -->
              <el-col :span="8">
                <el-form-item label="振込名義" prop="blpyr1">
                  <el-input size="small" v-model="form.blpyr1" placeholder="半角カタカナ" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row :span="3">
              <el-col :span="24">
                <el-form-item label="支払特記">
                  <el-input type="textarea" size="small" v-model="form.paymentNote" :rows="7" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
                </el-form-item>
              </el-col>
            </el-row> 
          </el-col> 
        </el-row>
        <!--請求書送付先-->
        <el-form-item class="sub-title" label="請求書送付先" v-if="this.accountInfo.role <= 3">
        </el-form-item>
        <el-row v-if="this.accountInfo.role <= 3">
          <el-col :span="11">
            <el-form-item label="メールアドレス①" prop="blplm7" label-width="160px">
              <el-input size="small" v-model="form.blplm7" style="width: 90%;" :disabled="this.form.payeeMode == 1 && this.form.blplmMode == 1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="メールアドレス②" prop="blplm8" label-width="160px">
              <el-input size="small" v-model="form.blplm8" style="width: 94%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="this.accountInfo.role <= 3">
          <el-col :span="11">
            <el-form-item label="メールアドレス③" prop="blplm9" label-width="160px">
              <el-input size="small" v-model="form.blplm9" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="メールアドレス④" prop="blplm10" label-width="160px">
              <el-input size="small" v-model="form.blplm10" style="width: 94%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!--サービス-->
        <el-divider content-position="left">
          <el-badge value="必須">
            <span class="sub-title">{{$t('addCustomer.service')}}</span>
          </el-badge>
        </el-divider>
        <el-row>
          <el-col :span="6">
            <el-button size="small" type="primary" icon="el-icon-plus" round @click="addServiceTemp" style="margin-left: 60px;margin-bottom: 10px;">{{$t('addCustomer.add')}}</el-button>
          </el-col>
        </el-row>
        <!--サービステーブル-->
        <el-table :data="form.serviceList" border stripe class="pp-table">
          <el-table-column prop="cdsvnmNm" label="サービス名" min-width="180px"></el-table-column>
          <el-table-column prop="cdplnm" label="商品名" min-width="150px"></el-table-column>
          <el-table-column prop="cdpbarNm" label="掲載エリア" min-width="120px"></el-table-column>
          <el-table-column label="リスティング情報" min-width="200px">
            <template slot-scope="scope">
              <div v-if="scope.row.cdlti1Nm">① {{scope.row.cdlti1Nm}}</div>
              <div v-if="scope.row.cdlti2Nm">② {{scope.row.cdlti2Nm}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="cdslcn" label="数量" min-width="80px"></el-table-column>
          <el-table-column label="期間" min-width="80px">
            <template slot-scope="scope">
              <div v-if="scope.row.cdsvnmNm != 'リクラクATS'">{{scope.row.cdterm}}</div>
            </template>
          </el-table-column>
          <el-table-column label="開始日" min-width="110px">
            <template slot-scope="scope">
              <div v-if="scope.row.cdsvnmNm != 'リクラクATS'">{{formatStrDate(scope.row.cdstrd)}}</div>
            </template>
          </el-table-column>
          <el-table-column label="終了日" min-width="110px">
            <template slot-scope="scope">
              <div v-if="scope.row.cdsvnmNm != 'リクラクATS'">{{formatEndDate(scope.row.cdendd)}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="cdsprc" label="合計額" min-width="120px">
            <template slot-scope="scope">
              <div>{{scope.row.cdsprc | currency('¥', 0)}}</div>
            </template>
          </el-table-column>
          <el-table-column label="CP" min-width="200px">
            <template slot-scope="scope">
              <div v-if="scope.row.cdcpn1Nm">① {{scope.row.cdcpn1Nm}}</div>
              <div v-if="scope.row.cdcpn2Nm">② {{scope.row.cdcpn2Nm}}</div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('task.process')" min-width="150px">
            <template slot-scope="scope">
              <el-button type="success" size="mini" plain round @click="handleUpdateS(scope.$index,scope.row)">修正</el-button>
              <el-button type="danger" size="mini" plain round @click="handleDeleteS(scope.$index)">削除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- <el-row>
          <el-col :span="7">
            <el-form-item label="請求書先発行">
              <el-select size="small" v-model="form.invoiceIssue" clearable style="width: 94%;">
                <el-option
                  v-for="item in invoiceIssueOptions"
                  v-bind:key="item.value"
                  v-bind:label="item.label"
                  v-bind:value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="PDFいつまでに必要" label-width="140px">
              <el-input size="small" v-model="form.pdfDate" style="width: 94%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="いつまでに発送か？" label-width="160px">
              <el-input size="small" v-model="form.sendDate" style="width: 94%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
        <!--DIPフロントシステム情報-->
        <el-divider content-position="left" v-if="this.accountInfo.role <= 3">
            <span class="sub-title">{{$t('addCustomer.dipSysInfo')}}</span>
        </el-divider>
        <el-divider content-position="left" v-else>
          <el-badge value="必須">
            <span class="sub-title">{{$t('addCustomer.dipSysInfo')}}</span>
          </el-badge>
        </el-divider>
        <el-row>
          <el-col :span="11">
            <el-form-item label="掲載区分">
              <el-select size="small" v-model="form.classKbn" @change="handleChangeClassKbn" style="width: 91%;">
                <el-option
                  v-for="item in classificationOptions"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" v-if="(accountInfo.role > 3 && form.gropid == null ) || form.gropid > 3">
            <el-form-item label="職種カテゴリ">
              <el-select size="small" v-model="form.jobCategory" style="width: 94%;">
                <el-option
                  v-for="item in jobList"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="(accountInfo.role > 3 && form.gropid == null ) || form.gropid > 3">
          <el-col :span="1" style="text-align: right;">
              <el-checkbox v-model="form.scndAgencyChk" style="margin-top: 11px;width: 90%;"></el-checkbox>
          </el-col>
          <el-col :span="10">
            <el-form-item label="二次代理店" prop="scndAgency" v-if="this.accountInfo.role > 3">
              <el-input size="small" v-model="form.scndAgency" placeholder="二次代理店名" style="width: 90%;"></el-input>
            </el-form-item>
            <el-form-item label="二次代理店" v-else>
              <el-input size="small" v-model="form.scndAgency" placeholder="二次代理店名" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="担当者" prop="scndMbCd" v-if="this.accountInfo.role > 3">
              <el-select size="small" v-model="form.scndMbCd" placeholder="キーワードを入力してください。" filterable remote :remote-method="queryScndMbList" style="width: 90%;">
                <el-option
                  v-for="item in scndMbList"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="担当者" v-else>
              <el-select size="small" v-model="form.scndMbCd" placeholder="キーワードを入力してください。" filterable remote :remote-method="queryScndMbList" style="width: 90%;">
                <el-option
                  v-for="item in scndMbList"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!--契約内容-->
        <el-divider content-position="left">
          <el-badge value="必須">
            <span class="sub-title">{{$t('addCustomer.contract')}}</span>
          </el-badge>
        </el-divider>
        <el-form-item label="原本当日回収 済み" label-width="170px">
        </el-form-item>
        <el-row>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.applyDay')" prop="cbapdt">
              <el-date-picker type="date" size="small" v-model="form.cbapdt" placeholder="選択してください" 
              format="yyyy/MM/dd" value-format="yyyy-MM-dd HH:mm:ss" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="新・継続区分" prop="cbcflg">
              <el-select size="small" v-model="form.cbcflg" style="width: 90%;" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in cbcflgOptions"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.job')" prop="cboccp">
              <el-select size="small" v-model="form.cboccp" style="width: 90%;" @change="handleChangeCboccp" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in jobList"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item :label="$t('addCustomer.printKbn')">
              <el-select size="small" v-model="form.cbpkbn" style="width: 90%;">
                <el-option
                  v-for="item in printOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="6" v-if="(accountInfo.role > 3 && form.gropid == null ) || form.gropid > 3">
            <el-form-item :label="$t('addCustomer.newDay')">
              <el-input size="small" v-model="form.cbncdy" style="width: 90%;" :disabled="true"></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.visitTime')" prop="visitDate">
              <el-date-picker type="date" size="small" v-model="form.visitDate" placeholder="選択してください" 
              format="yyyy/MM/dd" value-format="yyyy-MM-dd HH:mm:ss" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="visitTime" style="margin-left:-100px;">
              <el-select size="small" v-model="form.visitTime" placeholder="時刻選択" style="width: 80%;" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in visitTimeOptions"
                  v-bind:key="item.value"
                  v-bind:label="item.label"
                  v-bind:value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-form-item prop="visitChk" style="margin-left:-100px;">
              <el-checkbox v-model="form.visitChk" style="width: 85%;" :disabled="this.$route.query.status == 3">現地で確認済です。</el-checkbox>
            </el-form-item>
          </el-col> 
        </el-row>
        <!--担当者（１）~ 担当者（3）~ 担当者（10）-->
        <el-form-item class="sub-title" label="担当者（１）" v-if="accountInfo.role <= 3">
        </el-form-item>
        <el-row v-if="accountInfo.role <= 3">
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.section1')" prop="sectionId11">
              <el-select size="small" v-model="form.sectionId11" style="width: 90%;" @change="handleChangeSection11" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in section1List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.section2')" prop="sectionId12">
              <el-select size="small" v-model="form.sectionId12" style="width: 90%;" @change="handleChangeSection12" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in section12List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.business')" prop="cbsic1">
              <el-select size="small" v-model="form.cbsic1" style="width: 90%;" @change="handleChangeCbsic" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in member1List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.profitSharing')" prop="cbrte1">
              <el-input size="small" v-model="form.cbrte1" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="sub-title" label="担当者（２）" v-if="accountInfo.role <= 3">
          <el-col :span="6">
            <el-button size="mini" type="success" round @click="handleClear(2)" style="margin-left: 5px;">クリア</el-button>
          </el-col>
        </el-form-item>
        <el-row v-if="accountInfo.role <= 3">
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.section1')" prop="sectionId21">
              <el-select size="small" v-model="form.sectionId21" style="width: 90%;" @change="handleChangeSection21" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in section1List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.section2')" prop="sectionId22">
              <el-select size="small" v-model="form.sectionId22" style="width: 90%;" @change="handleChangeSection22" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in section22List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.business')" prop="cbsic2">
              <el-select size="small" v-model="form.cbsic2" style="width: 90%;" @change="handleChangeCbsic" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in member2List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.profitSharing')" prop="cbrte2">
              <el-input size="small" v-model="form.cbrte2" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="sub-title" label="担当者（３）" v-if="accountInfo.role <= 3">
          <el-col :span="6">
            <el-button size="mini" type="success" round @click="handleClear(3)" style="margin-left: 5px;">クリア</el-button>
          </el-col>
        </el-form-item>
        <el-row v-if="accountInfo.role <= 3">
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.section1')" prop="sectionId31">
              <el-select size="small" v-model="form.sectionId31" style="width: 90%;" @change="handleChangeSection31" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in section1List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.section2')" prop="sectionId32">
              <el-select size="small" v-model="form.sectionId32" style="width: 90%;" @change="handleChangeSection32" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in section32List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.business')" prop="cbsic3">
              <el-select size="small" v-model="form.cbsic3" style="width: 90%;" @change="handleChangeCbsic" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in member3List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.profitSharing')" prop="cbrte3">
              <el-input size="small" v-model="form.cbrte3" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="sub-title" label="担当者（４）" v-if="accountInfo.role <= 3">
          <el-col :span="6">
            <el-button size="mini" type="success" round @click="handleClear(4)" style="margin-left: 5px;">クリア</el-button>
          </el-col>
        </el-form-item>
        <el-row v-if="accountInfo.role <= 3">
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.section1')" prop="sectionId41">
              <el-select size="small" v-model="form.sectionId41" style="width: 90%;" @change="handleChangeSection41" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in section1List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.section2')" prop="sectionId42">
              <el-select size="small" v-model="form.sectionId42" style="width: 90%;" @change="handleChangeSection42" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in section42List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.business')" prop="cbsic4">
              <el-select size="small" v-model="form.cbsic4" style="width: 90%;" @change="handleChangeCbsic" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in member4List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.profitSharing')" prop="cbrte4">
              <el-input size="small" v-model="form.cbrte4" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="sub-title" label="担当者（５）" v-if="accountInfo.role <= 3">
          <el-col :span="6">
            <el-button size="mini" type="success" round @click="handleClear(5)" style="margin-left: 5px;">クリア</el-button>
          </el-col>
        </el-form-item>
        <el-row v-if="accountInfo.role <= 3">
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.section1')" prop="sectionId51">
              <el-select size="small" v-model="form.sectionId51" style="width: 90%;" @change="handleChangeSection51" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in section1List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.section2')" prop="sectionId52">
              <el-select size="small" v-model="form.sectionId52" style="width: 90%;" @change="handleChangeSection52" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in section52List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.business')" prop="cbsic5">
              <el-select size="small" v-model="form.cbsic5" style="width: 90%;" @change="handleChangeCbsic" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in member5List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.profitSharing')" prop="cbrte5">
              <el-input size="small" v-model="form.cbrte5" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="sub-title" label="担当者（６）" v-if="accountInfo.role <= 3">
          <el-col :span="6">
            <el-button size="mini" type="success" round @click="handleClear(6)" style="margin-left: 5px;">クリア</el-button>
          </el-col>
        </el-form-item>
        <el-row v-if="accountInfo.role <= 3">
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.section1')" prop="sectionId61">
              <el-select size="small" v-model="form.sectionId61" style="width: 90%;" @change="handleChangeSection61" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in section1List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.section2')" prop="sectionId62">
              <el-select size="small" v-model="form.sectionId62" style="width: 90%;" @change="handleChangeSection62" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in section62List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.business')" prop="cbsic6">
              <el-select size="small" v-model="form.cbsic6" style="width: 90%;" @change="handleChangeCbsic" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in member6List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.profitSharing')" prop="cbrte6">
              <el-input size="small" v-model="form.cbrte6" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="sub-title" label="担当者（７）" v-if="accountInfo.role <= 3">
          <el-col :span="6">
            <el-button size="mini" type="success" round @click="handleClear(7)" style="margin-left: 5px;">クリア</el-button>
          </el-col>
        </el-form-item>
        <el-row v-if="accountInfo.role <= 3">
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.section1')" prop="sectionId71">
              <el-select size="small" v-model="form.sectionId71" style="width: 90%;" @change="handleChangeSection71" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in section1List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.section2')" prop="sectionId72">
              <el-select size="small" v-model="form.sectionId72" style="width: 90%;" @change="handleChangeSection72" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in section72List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.business')" prop="cbsic7">
              <el-select size="small" v-model="form.cbsic7" style="width: 90%;" @change="handleChangeCbsic" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in member7List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.profitSharing')" prop="cbrte7">
              <el-input size="small" v-model="form.cbrte7" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="sub-title" label="担当者（８）" v-if="accountInfo.role <= 3">
          <el-col :span="6">
            <el-button size="mini" type="success" round @click="handleClear(8)" style="margin-left: 5px;">クリア</el-button>
          </el-col>
        </el-form-item>
        <el-row v-if="accountInfo.role <= 3">
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.section1')" prop="sectionId81">
              <el-select size="small" v-model="form.sectionId81" style="width: 90%;" @change="handleChangeSection81" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in section1List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.section2')" prop="sectionId82">
              <el-select size="small" v-model="form.sectionId82" style="width: 90%;" @change="handleChangeSection82" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in section82List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.business')" prop="cbsic8">
              <el-select size="small" v-model="form.cbsic8" style="width: 90%;" @change="handleChangeCbsic" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in member8List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.profitSharing')" prop="cbrte8">
              <el-input size="small" v-model="form.cbrte8" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="sub-title" label="担当者（９）" v-if="accountInfo.role <= 3">
          <el-col :span="6">
            <el-button size="mini" type="success" round @click="handleClear(9)" style="margin-left: 5px;">クリア</el-button>
          </el-col>
        </el-form-item>
        <el-row v-if="accountInfo.role <= 3">
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.section1')" prop="sectionId91">
              <el-select size="small" v-model="form.sectionId91" style="width: 90%;" @change="handleChangeSection91" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in section1List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.section2')" prop="sectionId92">
              <el-select size="small" v-model="form.sectionId92" style="width: 90%;" @change="handleChangeSection92" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in section92List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.business')" prop="cbsic9">
              <el-select size="small" v-model="form.cbsic9" style="width: 90%;" @change="handleChangeCbsic" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in member9List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.profitSharing')" prop="cbrte9">
              <el-input size="small" v-model="form.cbrte9" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="sub-title" label="担当者（１０）" v-if="accountInfo.role <= 3">
          <el-col :span="6">
            <el-button size="mini" type="success" round @click="handleClear(10)" style="margin-left: 5px;">クリア</el-button>
          </el-col>
        </el-form-item>
        <el-row v-if="accountInfo.role <= 3">
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.section1')" prop="sectionId101">
              <el-select size="small" v-model="form.sectionId101" style="width: 90%;" @change="handleChangeSection101" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in section1List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.section2')" prop="sectionId102">
              <el-select size="small" v-model="form.sectionId102" style="width: 90%;" @change="handleChangeSection102" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in section102List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.business')" prop="cbsic10">
              <el-select size="small" v-model="form.cbsic10" style="width: 90%;" @change="handleChangeCbsic" :disabled="this.$route.query.status == 3">
                <el-option
                  v-for="item in member10List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('addCustomer.profitSharing')" prop="cbrte10">
              <el-input size="small" v-model="form.cbrte10" style="width: 90%;" :disabled="this.$route.query.status == 3"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-col :span="6">
            <el-form-item label="引継ぎ">
              <el-input size="small" v-model="form.inheritContent" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
        <!--募集先情報-->
        <el-divider content-position="left">
          <span class="sub-title">{{$t('addCustomer.recruiting')}}</span>
        </el-divider>
        <el-row>
          <el-col :span="6">
            <el-button size="small" type="primary" icon="el-icon-plus" round @click="addRecruitingTemp" style="margin-left: 60px;margin-bottom: 10px;">{{$t('addCustomer.add')}}</el-button>
          </el-col>
        </el-row>
        <el-table :data="form.recruitingList" border stripe class="pp-table">
          <el-table-column prop="advtid" label="募集先ID" min-width="140px"></el-table-column>
          <el-table-column prop="advtkj" label="店舗名（漢）" min-width="200px"></el-table-column>
          <el-table-column prop="address1" label="都道府県" min-width="140px"></el-table-column>
          <el-table-column prop="address2" label="市区町村" min-width="200px"></el-table-column>
          <el-table-column prop="address3" label="番地・建物名以下" min-width="250px"></el-table-column>
          <el-table-column prop="advtel" label="店舗TEL" min-width="140px"></el-table-column>
          <!-- <el-table-column prop="adbztyNm" label="業種" min-width="140px" v-if="(accountInfo.role > 3 && form.gropid == null ) || form.gropid > 3"></el-table-column> -->
          <el-table-column :label="$t('task.process')" min-width="150px">
            <template slot-scope="scope">
              <el-button type="success" size="mini" plain round @click="handleUpdateR(scope.$index,scope.row)">修正</el-button>
              <el-button type="danger" size="mini" plain round @click="handleDeleteR(scope.$index)">削除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--申込時必要情報-->
        <el-divider content-position="left">
          <span class="sub-title">{{$t('addCustomer.requiredInfo')}}</span>
        </el-divider>
        <!-- <el-row v-if="(accountInfo.role > 3 && form.gropid == null ) || form.gropid > 3">
          <el-col :span="7">
            <el-form-item label="ご担当者様電話番号" prop="mbPhoneNum" label-width="140px">
              <el-input size="small" v-model="form.mbPhoneNum" style="width: 94%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="取材（有・無）" label-width="110px">
              <el-switch v-model="form.interview" active-value="1" inactive-value="0" active-text="有" inactive-text="無" style="margin-left: 100px;"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="検索連動バナー（エリア・路線）" label-width="225px">
              <el-input size="small" v-model="form.bannerSearch1" style="width: 94%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="(accountInfo.role > 3 && form.gropid == null ) || form.gropid > 3">
          <el-col :span="7">
            <el-form-item label="ご担当者様メールアドレス" prop="mbMailAddress" label-width="180px">
              <el-input size="small" v-model="form.mbMailAddress" style="width: 94%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="期待応募数" label-width="110px">
              <el-input size="small" v-model="form.expectedNum" style="width: 94%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="検索連動バナー（職種）" label-width="225px">
              <el-input size="small" v-model="form.bannerSearch2" style="width: 94%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row v-if="accountInfo.role <= 3">
          <el-col :span="7">
            <el-form-item label="内勤への特記事項" label-width="140px">
              <el-input type="textarea" size="small" v-model="form.remark" :rows="4" style="width: 94%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-else>
          <el-col :span="7">
            <el-form-item label="dipフロントシステム備考欄" label-width="190px">
              <el-input type="textarea" size="small" v-model="form.remark" :rows="4" style="width: 94%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!--SAAS-->
        <el-divider content-position="left" v-if="accountInfo.role <= 3 && (form.gropid == null || form.gropid <= 3)">
          <span class="sub-title">{{$t('addCustomer.saas')}}</span>
        </el-divider>
        <el-row v-if="accountInfo.role <= 3 && (form.gropid == null || form.gropid <= 3)">
          <el-col :span="15">
            <el-form-item label="SAASに表示するコメント" label-width="175px">
              <el-input size="small" v-model="form.saasCmmt" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!--クラウドサイン情報-->
        <el-divider content-position="left" v-if="accountInfo.role <= 3 && (form.gropid == null || form.gropid <= 3)">
          <el-badge value="必須">
            <span class="sub-title">{{$t('addCustomer.cloudSign')}}</span>
          </el-badge>
        </el-divider>
        <el-row v-if="accountInfo.role <= 3 && (form.gropid == null || form.gropid <= 3)">
          <el-col :span="1">
            <el-form-item label="担当者1">
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="メアド" prop="mb1MailAddress">
              <el-input size="small" v-model="form.mb1MailAddress" :disabled="true" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="会社名">
              <el-input size="small" v-model="form.mb1CompanyName" :disabled="true" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="氏名" prop="mb1Name">
              <el-input size="small" v-model="form.mb1Name" :disabled="true" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row v-if="accountInfo.role <= 3">
          <el-col :span="1">
            <el-form-item label="担当者2">
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="メアド">
              <el-input size="small" v-model="form.mb2MailAddress" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="会社名">
              <el-input size="small" v-model="form.mb2CompanyName" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="氏名">
              <el-input size="small" v-model="form.mb2Name" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row v-if="accountInfo.role <= 3 && (form.gropid == null || form.gropid <= 3)">
          <el-col :span="1">
            <el-form-item label="担当者2">
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="メアド" prop="mb3MailAddress">
              <el-input size="small" v-model="form.mb3MailAddress" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="会社名">
              <el-input size="small" v-model="form.mb3CompanyName" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="氏名" prop="mb3Name">
              <el-input size="small" v-model="form.mb3Name" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="accountInfo.role <= 3 && (form.gropid == null || form.gropid <= 3)">
          <el-col :span="1">
            <el-form-item label="担当者3">
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="メアド" prop="mb4MailAddress">
              <el-input size="small" v-model="form.mb4MailAddress" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="会社名">
              <el-input size="small" v-model="form.mb4CompanyName" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="氏名" prop="mb4Name">
              <el-input size="small" v-model="form.mb4Name" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="accountInfo.role <= 3 && (form.gropid == null || form.gropid <= 3)">
          <el-col :span="1">
            <el-form-item label="共有先1">
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="メアド" prop="gy1MailAddress">
              <el-input size="small" v-model="form.gy1MailAddress" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="氏名" prop="gy1Name">
              <el-input size="small" v-model="form.gy1Name" style="width: 94%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="accountInfo.role <= 3 && (form.gropid == null || form.gropid <= 3)">
          <el-col :span="1">
            <el-form-item label="共有先2">
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="メアド" prop="gy2MailAddress">
              <el-input size="small" v-model="form.gy2MailAddress" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="氏名" prop="gy2Name">
              <el-input size="small" v-model="form.gy2Name" style="width: 94%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="accountInfo.role <= 3 && (form.gropid == null || form.gropid <= 3)">
          <el-col :span="1">
            <el-form-item label="共有先3">
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="メアド" prop="gy3MailAddress">
              <el-input size="small" v-model="form.gy3MailAddress" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="氏名" prop="gy3Name">
              <el-input size="small" v-model="form.gy3Name" style="width: 94%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="accountInfo.role <= 3 && (form.gropid == null || form.gropid <= 3)">
          <el-col :span="1">
            <el-form-item label="共有先4">
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="メアド" prop="gy4MailAddress">
              <el-input size="small" v-model="form.gy4MailAddress" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="氏名" prop="gy4Name">
              <el-input size="small" v-model="form.gy4Name" style="width: 94%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-form-item label="クラウドサイン情報（2）" label-width="180px">
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item label="庶務担当者" prop="cbgaic">
              <el-select size="small" v-model="form.cbgaic" style="width: 91%;">
                <el-option
                  v-for="item in generalManagerOptions"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
            <div class="mask-full" v-if="accountInfo.role == 4"></div>
          </el-col>
        </el-row>
        <!--請求フローをお選び下さい-->
        <el-divider content-position="left" v-if="(accountInfo.role > 3 && form.gropid == null ) || form.gropid > 3">
          <span class="sub-title">請求フローをお選び下さい</span>
        </el-divider>
        <el-row v-if="(accountInfo.role > 3 && form.gropid == null ) || form.gropid > 3">
          <el-col :span="6">
            <el-form-item prop="appFlow" label-width="40px">
              <el-radio-group v-model="form.appFlow" style="width: 90%;">
                  <el-radio :label=1>　開始月ごとに請求　</el-radio>
                  <el-radio :label=2>請求一括</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <!--按钮部-->
        <el-form-item style="text-align: right; margin-top: 40px; margin-right: 40px;">
          <el-button type="success" round @click="saveForm('form')" style="width: 120px;margin-right: 20px;" v-if="this.$route.query.status != 5 && (form.prcsst == null || form.prcsst == 0)">一時保存</el-button>
          <el-button type="primary" round @click="submitForm('form')" style="width: 120px;margin-right: 20px;" v-if="(form.prcsst == null || form.prcsst == 0 || form.prcsst == 1 || form.prcsst == 99) && this.$route.query.status != 4">確認依頼</el-button>
          <el-button type="warning" round @click="confirmForm('form')" style="width: 120px;margin-right: 20px;" v-if="form.prcsst == 1 && this.$route.query.status == 4 && accountInfo.role <= 2">最終登録</el-button>
          <el-button round @click="resetForm()" style="width: 120px;">{{$t('addCustomer.back')}}</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!--サービステンプ-->
    <el-dialog title="サービス" :visible.sync="serviceForm.addServiceVisible" width="60%">
      <el-form ref="serviceForm" :model="serviceForm" label-width="155px" :rules="serviceRules" class="pp-service">
        <el-row>
          <el-col :span="12">
            <el-form-item label="種別（申）" prop="cdsvtpsr">
              <el-select size="small" v-model="serviceForm.cdsvtpsr" placeholder="申込書" @change="handleChangeStpsr" style="width: 90%;">
                <el-option
                  v-for="item in cdsvtpsrList"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数量" prop="cdslcn">
              <el-input size="small" v-model="serviceForm.cdslcn" @change="handleCalculatePrice" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="種別（B）" prop="cdsvtp">
              <el-select size="small" v-model="serviceForm.cdsvtp" placeholder="BIMS" style="width: 90%;" :disabled="true">
                <el-option
                  v-for="item in cdsvtpList"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="単価" prop="cdlprc">
              <el-input size="small" v-model="serviceForm.cdlprc" style="width: 90%;" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row> 
        <el-row>
          <el-col :span="12"> 
            <el-form-item label="サービス名（申）" prop="cdsvnmsr">
              <el-select size="small" v-model="serviceForm.cdsvnmsr" placeholder="申込書" @change="handleChangeSvnmsr" style="width: 90%;">
                <el-option
                  v-for="item in cdsvnmsrList"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="値引き" prop="cddcnt">
              <el-input size="small" v-model="serviceForm.cddcnt" @change="handleCalculatePrice" style="width: 90%;"></el-input>
            </el-form-item>
            <div class="mask" v-if="accountInfo.role == 4"></div>
          </el-col>
        </el-row> 
        <el-row>
          <el-col :span="12">
            <el-form-item label="サービス名（B）" prop="cdsvnm">
              <el-select size="small" v-model="serviceForm.cdsvnm" placeholder="BIMS" @change="handleCalculatePrice" style="width: 90%;">
                <el-option
                  v-for="item in cdsvnmList"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="小計" prop="cdstrc">
              <el-input size="small" v-model="serviceForm.cdstrc" @change="handleCalculatePrice" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row> 
        <el-row>
          <el-col :span="12">
            <el-form-item label="プラン名" prop="cdplcd">
              <el-select size="small" v-model="serviceForm.cdplcd" filterable allow-create @change="handleChangePlnm" style="width: 90%;">
                <el-option
                  v-for="item in cdplnmList"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合計額" prop="cdsprc">
              <el-input size="small" v-model="serviceForm.cdsprc" style="width: 90%;" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row> 
        <el-row>
          <el-col :span="12">
            <el-form-item label="掲載エリア" prop="cdpbar">
              <el-select size="small" v-model="serviceForm.cdpbar" @change="handleChangePrta" style="width: 90%;">
                <el-option
                  v-for="item in printAreaList"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="原価" prop="cdcost" v-if="accountInfo.role <= 3">
              <el-input size="small" v-model="serviceForm.cdcost" style="width: 90%;"></el-input>
            </el-form-item>
            <el-form-item label="原価" v-else>
              <el-input size="small" v-model="serviceForm.cdcost" style="width: 90%;"></el-input>
            </el-form-item>
            <div class="mask" v-if="accountInfo.role == 4"></div>
          </el-col>
        </el-row> 
        <el-row>
          <el-col :span="12">
            <el-form-item label="リスティング情報①" prop="cdlti1">
              <el-select size="small" v-model="serviceForm.cdlti1" @change="handleChangeInfo" style="width: 90%;">
                <el-option
                  v-for="item in listInfo1List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="利益" prop="cdprft" v-if="accountInfo.role <= 3">
              <el-input size="small" v-model="serviceForm.cdprft" style="width: 90%;"></el-input>
            </el-form-item>
            <el-form-item label="利益" v-else>
              <el-input size="small" v-model="serviceForm.cdprft" style="width: 90%;"></el-input>
            </el-form-item>
            <div class="mask" v-if="accountInfo.role == 4"></div>
          </el-col>
        </el-row> 
        <el-row>
          <el-col :span="12">
            <el-form-item label="リスティング情報②" prop="cdlti2">
              <el-select size="small" v-model="serviceForm.cdlti2" style="width: 90%;">
                <el-option
                  v-for="item in listInfo2List"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="掲載期間" prop="cdterm">
              <el-input size="small" v-model="serviceForm.cdterm" style="width: 90%;" @change="handleCalEndDate"></el-input>
            </el-form-item>
          </el-col>
        </el-row> 
        <el-row>
          <el-col :span="12">
            <el-form-item label="キャンペーン①">
              <el-select size="small" v-model="serviceForm.cdcpn1" placeholder="FS登録用" clearable style="width: 90%;">
                <el-option
                  v-for="item in cdcpnList"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="掲載開始日" prop="cdstrd">
              <el-date-picker type="date" size="small" v-model="serviceForm.cdstrd" format="yyyy/MM/dd" 
                value-format="yyyy-MM-dd HH:mm:ss" @change="handleSetApplyDate" style="width: 90%;"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row> 
        <el-row>
          <el-col :span="12">
            <el-form-item label="キャンペーン②">
              <el-select size="small" v-model="serviceForm.cdcpn2" placeholder="FS登録用" clearable style="width: 90%;">
                <el-option
                  v-for="item in cdcpnList"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="掲載終了日" prop="cdendd">
              <el-date-picker type="date" size="small" v-model="serviceForm.cdendd" format="yyyy/MM/dd" 
                value-format="yyyy-MM-dd HH:mm:ss" style="width: 90%;"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row> 
        <el-row>
          <el-col :span="12">
            <el-form-item label="オールリスティングの職種" label-width="180px">
              <el-select size="small" v-model="serviceForm.cdoccp" placeholder="FS登録用" style="width: 89%;">
                <el-option
                  v-for="item in serviceJobList"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="請求・支払　分割数" prop="cdblcn">
              <el-input size="small" v-model="serviceForm.cdblcn" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row> 
        <el-row>
          <el-col :span="12">
            <el-form-item label="営業利益計上月" prop="profit" v-if="accountInfo.role <= 3">
              <el-date-picker type="month" size="small" v-model="serviceForm.profit" 
              format="yyyy/MM" value-format="yyyy/MM" style="width: 90%;"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="請求日" prop="blblpd" v-if="accountInfo.role <= 3">
              <el-date-picker type="date" size="small" v-model="serviceForm.blblpd" format="yyyy/MM/dd" 
                value-format="yyyy-MM-dd HH:mm:ss" style="width: 90%;"></el-date-picker>
            </el-form-item>
            <!-- <el-form-item label="請求書内備考" prop="cdbcmt" v-else>
              <el-input type="textarea" size="small" v-model="serviceForm.cdbcmt" :rows="4" style="width: 90%;"></el-input>
            </el-form-item> -->
          </el-col>
        </el-row> 
        <el-row>
          <el-col :span="12">
           <el-form-item label="営業利益分割数" prop="profitDiv" v-if="accountInfo.role <= 3">
              <el-input size="small" v-model="serviceForm.profitDiv" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="支払日" prop="bldppd" v-if="accountInfo.role <= 3">
              <el-date-picker type="date" size="small" v-model="serviceForm.bldppd" format="yyyy/MM/dd" 
                value-format="yyyy-MM-dd HH:mm:ss" style="width: 90%;"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="請求書内備考" prop="cdbcmt" v-if="accountInfo.role <= 3">
              <el-input type="textarea" size="small" v-model="serviceForm.cdbcmt" :rows="4" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" round @click="saveService('serviceForm')" v-if="serviceForm.mode == 0">{{$t('addCustomer.confirm')}}</el-button>
        <el-button type="primary" round @click="updateService('serviceForm')" v-if="serviceForm.mode == 1">更新</el-button>
        <el-button round @click="serviceForm.addServiceVisible = false">{{$t('addCustomer.back')}}</el-button>
      </div>
    </el-dialog>

    <!--募集先情報テンプ-->
    <el-dialog title="募集先" :visible.sync="recruitingForm.addVisible" width="75%">
      <el-form ref="recruitingForm" :model="recruitingForm" label-width="140px" :rules="recruitingRules" class="pp-service">
        <el-row>      
          <el-col :span="12">
            <el-form-item label="募集先">
              <!-- <el-input size="small" v-model="recruitingForm.advtid" placeholder="募集先ID" clearable style="width: 90%;"></el-input> -->
              <el-select size="small" v-model="recruitingForm.advtid" @change="handleChangeAdvt" style="width: 90%;">
                <el-option
                  v-for="item in advtidList"
                  v-bind:key="item.advtid"
                  v-bind:label="item.advtid + '　' + item.advtkj"
                  v-bind:value="item.advtid">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <!-- <el-button size="small" type="success" class="pp-btn" icon="el-icon-search" plain round @click="searchRecruiting" >{{$t('addCustomer.search')}}</el-button> -->
            <el-switch v-model="recruitingForm.adstcd" :active-value=0 :inactive-value=1 :active-text="$t('addCustomer.add')" @change="addRecruitingFlag" style="margin-left: 28px;margin-top: 9px"></el-switch>
          </el-col>
          <el-col :span="1">
            <el-form-item>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- <el-form-item :label="$t('addCustomer.sameInformation')" prop="copyFlg">
              <el-select size="small" v-model="recruitingForm.copyFlg" style="width: 90%;" @change="handleRecruitingCopy">
                <el-option
                  v-for="item in copyInfoOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item> -->
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="店舗名（漢）" prop="advtkj">
              <el-input size="small" v-model="recruitingForm.advtkj" placeholder="漢字" :disabled="recruitingForm.adstcd == 1" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="番地・建物名以下">
              <el-input size="small" v-model="recruitingForm.address3" :disabled="recruitingForm.adstcd == 1" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="都道府県" prop="address1">
              <el-input size="small" v-model="recruitingForm.address1" :disabled="recruitingForm.adstcd == 1" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="店舗TEL" prop="advtel">
              <el-input size="small" v-model="recruitingForm.advtel" :disabled="recruitingForm.adstcd == 1" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row> 
        <el-row>
          <el-col :span="12">
            <el-form-item label="市区町村" prop="address2">
              <el-input size="small" v-model="recruitingForm.address2" :disabled="recruitingForm.adstcd == 1" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12" v-if="(accountInfo.role > 3 && form.gropid == null ) || form.gropid > 3">
            <el-form-item label="業種" prop="adbzty">
              <el-select size="small" v-model="recruitingForm.adbzty" :disabled="recruitingForm.adstcd == 1" style="width: 90%;">
                <el-option
                  v-for="item in adbztyList"
                  v-bind:key="item.kbCode"
                  v-bind:label="item.kbName"
                  v-bind:value="item.kbCode">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="コメント" prop="adcmmt">
              <el-input type="textarea" size="small" v-model="recruitingForm.adcmmt" :rows="4" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" round @click="addRecruiting('recruitingForm')" v-if="recruitingForm.mode == 0">{{$t('addCustomer.confirm')}}</el-button>
        <el-button type="primary" round @click="updateRecruiting('recruitingForm')" v-if="recruitingForm.mode == 1">更新</el-button>
        <el-button round @click="recruitingForm.addVisible = false">{{$t('addCustomer.back')}}</el-button>
      </div>
    </el-dialog>

    <!--登録確認テンプ-->
    <el-dialog title="結果確認" :visible.sync="okForm.isVisible" width="600px">
      <div class="createDialog" style="height: 40px;">
        <div style="text-align:center;">
          <span style="font-size:24px;">処理が成功しました。</span>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" round @click="returnList()">一覧へ戻る</el-button>
        <el-button type="primary" round @click="doPreview()" v-if="accountInfo.role <= 3">プレビュー</el-button>
      </div>
    </el-dialog>

    <!--仕事NO入力テンプ-->
    <el-dialog title="仕事NO入力" :visible.sync="businessForm.isVisible" width="600px">
        <el-form ref="businessForm" :model="businessForm" :rules="businessRules" label-width="120px">
            <el-row>
                <el-col :span="24">
                    <el-form-item label="仕事NO" prop="bizno">
                        <el-input size="small" v-model.trim="businessForm.bizno" placeholder="入力してください。" style="width: 90%;"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="small" round @click="businessForm.isVisible = false">戻る</el-button>
            <el-button size="small" round type="primary" class="pp-btn" @click="confirmBusiness('businessForm')">確認</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { getSshCustomer, getSshPayee, getSshRecruiting, saveCustomer, addCustomer, updateCustomer, 
  confirmCustomer, customerDetail, getKbnList, getSubKbnList, getLocalKbnList, getSubLocalKbnList, 
  getSubLocalKbnList2, getSubLocalKbnList3, getCampaignList, getMemberList, getManagerList, 
  getParentList, getZipInfo, getRateByName, getScndMbList, getAdvtidList, chkNameSr } from "@/api/customer";
import { groupDetail } from "@/api/admin";
import { mapState } from "vuex";
import { getAddressList, zeroPad, getDateTimeStr1, getLastDate, getNextMonthDay,
  getFormatYm,currency } from "@/utils/commonUtils";
import { ROUTER_PATH } from "@/constants/common.js";
import {isNullOrUndefinedOrEmpty} from "@/utils/stringHelper";
import axios from "axios";

export default {
  name: "AddCustomer",
  filters: {
    currency: currency
  },
  data() {
    const checkPhoneNumber = (rule, value, callback) => {
      var pattern1 = /^0([0-9]-[0-9]{4}|[0-9]{2}-[0-9]{3}|[0-9]{3}-[0-9]{2}|[0-9]{4}-[0-9])-[0-9]{4}$/;
      var pattern2 = /^0[5789]0-[0-9]{4}-[0-9]{4}$/;
      var pattern3 = /^0120-[0-9]{3}-[0-9]{3}$/;
      if (value) {
        if (!pattern1.test(value) && !pattern2.test(value) && !pattern3.test(value)) {
          return callback(new Error('TELを正しく入力してください。'))
        }
      }
      return callback();
    }
    const checkPhoneNumber2 = (rule, value, callback) => {
      var pattern1 = /^0([0-9]-[0-9]{4}|[0-9]{2}-[0-9]{3}|[0-9]{3}-[0-9]{2}|[0-9]{4}-[0-9])-[0-9]{4}$/;
      var pattern2 = /^0[5789]0-[0-9]{4}-[0-9]{4}$/;
      var pattern3 = /^0120-[0-9]{3}-[0-9]{3}$/;
      if (value) {
        if (!pattern1.test(value) && !pattern2.test(value) && !pattern3.test(value)) {
          return callback(new Error('店舗TELを正しく入力してください。'))
        }
      }
      return callback();
    }
    const validateBlcbid = (rule, value, callback) => {
      var pattern1 = /^B[0-9]{2}$/;
      var pattern2 = /^[0-9]{3}$/;
      if (value) {
        if (this.accountInfo.role <= 3 && (!pattern1.test(value) && !pattern2.test(value))) {
          return callback(new Error('「B」＋数字2桁又は数字のみ3桁の形式です。'))
        } else if (this.accountInfo.role == 4 && !pattern2.test(value)) {
          return callback(new Error('数字のみ3桁の形式です。'))
        }
      }
      return callback();
    }
    const validateClbdnm = (rule, value, callback) => {
      if (this.form.clbdnm != null && this.form.clbdnm != "" && (value == null || value == "")) {
        return callback(new Error('媒体名が入力されたの場合は必須入力です。'))
      }
      return callback();
    }
    const validateClcomp = (rule, value, callback) => {
      if (this.accountInfo.role > 3 && (value == null || value == "")) {
        return callback(new Error('CompNoを入力してください。'))
      }
      return callback();
    }
    const validateVisitDate = (rule, value, callback) => {
      // if ((this.accountInfo.role > 3 && this.form.gropid == null ) || this.form.gropid > 3) {
        //二次店的场合
        if (this.form.cboccp == 24 && !value) {
          return callback(new Error('職種で「★ガールズバー・キャバクラ・スナック」を選択した場合は必須入力です。'))
        }
      // }
      return callback();
    }
    const validateClickj = (rule, value, callback) => {
      if ((this.form.gropid == null || this.form.gropid <= 3) 
          && this.accountInfo.role <= 3 && (value == null || value == "")) {
        return callback(new Error('代表者名（漢字）を入力してください。'))
      }
      return callback();
    }
    const validateClickn = (rule, value, callback) => {
      if ((this.form.gropid == null || this.form.gropid <= 3) 
          && this.accountInfo.role <= 3 && (value == null || value == "")) {
        return callback(new Error('代表者名（フリカナ）を入力してください。'))
      }
      return callback();
    }
    const validateAppFlow = (rule, value, callback) => {
      var isDifferent = false
      var lastYear = 0
      var lastMonth = 0
      for (var i = 0; i < this.form.serviceList.length; i++) {
        //获取最小掲載開始日
        var serData = this.form.serviceList[i];
        var curDate = new Date(serData.cdstrd);
        var year = curDate.getFullYear();
        var month = curDate.getMonth() + 1;
        if ((lastYear === 0 && lastMonth === 0) || (lastYear === year && lastMonth === month)) {
          lastYear = year
          lastMonth = month
        } else {
          isDifferent = true
          break
        }
      }
      if (isDifferent && (value == null || value == "")) {
        return callback(new Error('サービスで掲載月が異なる場合、請求フローをお選び下さい。'))
      }
      return callback();
    }
    return {
      tmpCompNo: '',
      backupInfo: { restInPlan: [] },
      locationList: [],
      cbcflgOptions: [],
      jobList: [],
      scndMbList: [],
      serviceJobList: [],
      section1List: [],
      section12List: [],
      section22List: [],
      section32List: [],
      section42List: [],
      section52List: [],
      section62List: [],
      section72List: [],
      section82List: [],
      section92List: [],
      section102List: [],
      advtidList: [],
      member1List: [],
      member2List: [],
      member3List: [],
      member4List: [],
      member5List: [],
      member6List: [],
      member7List: [],
      member8List: [],
      member9List: [],
      member10List: [],
      generalManagerOptions: [],
      classificationOptions: [],
      // appMailCcOptions: [],
      weekDayList: ["日","月","火","水","木","金","土"],
      // appStatusOptions: [
      //   {value:0, label: "差戻中"},
      //   {value:1, label: "完了"}
      // ],
      sendOptions: [
        {value:0, label: "送信する"},
        {value:1, label: "送信しない"}
      ],
      copyInfoOptions: [
        {value:0, label: "顧客情報と同じ"},
        {value:1, label: "顧客情報と異なる"}
      ],
      transferFeeOptions: [
        {value:0, label: "顧客負担"},
        {value:1, label: "PP負担"}
      ],
      paymentOptions: [
        {value:0, label: "振込"},
        {value:1, label: "口座"}
      ],
      printOptions: [
        // {value:1, label: "必要・通常"},
        // {value:2, label: "必要・個別"},
        {value:3, label: "不要・通常"},
        {value:4, label: "請求対象外"},
      ],
      invoiceIssueOptions: [
        {value:1, label: "郵送のみ"},
        {value:2, label: "PDFのみ"},
        {value:3, label: "郵送とPDF両方"}
      ],
      visitTimeOptions: [
        {value:"", label: "時刻選択"},
        {value:"00:00", label: "12:00am"},
        {value:"00:30", label: "12:30am"},
        {value:"01:00", label: "1:00am"},
        {value:"01:30", label: "1:30am"},
        {value:"02:00", label: "2:00am"},
        {value:"02:30", label: "2:30am"},
        {value:"03:00", label: "3:00am"},
        {value:"03:30", label: "3:30am"},
        {value:"04:00", label: "4:00am"},
        {value:"04:30", label: "4:30am"},
        {value:"05:00", label: "5:00am"},
        {value:"05:30", label: "5:30am"},
        {value:"06:00", label: "6:00am"},
        {value:"06:30", label: "6:30am"},
        {value:"07:00", label: "7:00am"},
        {value:"07:30", label: "7:30am"},
        {value:"08:00", label: "8:00am"},
        {value:"08:30", label: "8:30am"},
        {value:"09:00", label: "9:00am"},
        {value:"09:30", label: "9:30am"},
        {value:"10:00", label: "10:00am"},
        {value:"10:30", label: "10:30am"},
        {value:"11:00", label: "11:00am"},
        {value:"11:30", label: "11:30am"},
        {value:"12:00", label: "12:00pm"},
        {value:"12:30", label: "12:30pm"},
        {value:"13:00", label: "1:00pm"},
        {value:"13:30", label: "1:30pm"},
        {value:"14:00", label: "2:00pm"},
        {value:"14:30", label: "2:30pm"},
        {value:"15:00", label: "3:00pm"},
        {value:"15:30", label: "3:30pm"},
        {value:"16:00", label: "4:00pm"},
        {value:"16:30", label: "4:30pm"},
        {value:"17:00", label: "5:00pm"},
        {value:"17:30", label: "5:30pm"},
        {value:"18:00", label: "6:00pm"},
        {value:"18:30", label: "6:30pm"},
        {value:"19:00", label: "7:00pm"},
        {value:"19:30", label: "7:30pm"},
        {value:"20:00", label: "8:00pm"},
        {value:"20:30", label: "8:30pm"},
        {value:"21:00", label: "9:00pm"},
        {value:"21:30", label: "9:30pm"},
        {value:"22:00", label: "10:00pm"},
        {value:"22:30", label: "10:30pm"},
        {value:"23:00", label: "11:00pm"},
        {value:"23:30", label: "11:30pm"}
      ],
      form: {
        payeeMode: 0,
        blplmMode: 0,
        corporateName: '',
        representative: '',
        mbllid: null,
        tcrbid: null,
        casdid: null,
        gropid: null,
        clcrpg: '',
        clclid: '',
        cllcid: null,
        clzipc: '',
        clmnkj: '',
        clmnkn: '',
        clmnid: '',
        clnmkj: '',
        clnmkn: '',
        cladkj1: '',
        cladkj2: '',
        cladkj3: '',
        cladkj: '',
        cladkn: '',
        clbdnm: '',
        clmnpw: '',
        clmnps: '',
        clmskj: '',
        clmskn: '',
        clcomp: '',
        clickj: '',
        clickn: '',
        clcmmt: '',
        clttel: '',
        cltfax: '',
        clicps: '',
        clplm1: '',
        clplm2: '採用ご担当者',
        clplm3: 1,
        clplm4: '',
        clstcd: 0,
        prcsst: 0,
        blclid: null,
        blcbid: '',
        copyFlg: 1,
        blnmkj: '',
        blnmkn: '',
        blzipc: '',
        blakj1: '',
        blakj2: '',
        recipientName: '',
        blltel: '',
        bllfax: '',
        blcmmt: '',
        blstcd: "0",
        bltflg: 0,
        pmtFlg: 0,
        blpyr1: '',
        blplm1: 0,
        blplm7: '',
        blplm8: '',
        blplm9: '',
        blplm10: '',
        sectionId11: '',
        sectionId12: '',
        sectionId21: '',
        sectionId22: '',
        sectionId31: '',
        sectionId32: '',
        sectionId41: '',
        sectionId42: '',
        sectionId51: '',
        sectionId52: '',
        sectionId61: '',
        sectionId62: '',
        sectionId71: '',
        sectionId72: '',
        sectionId81: '',
        sectionId82: '',
        sectionId91: '',
        sectionId92: '',
        sectionId101: '',
        sectionId102: '',
        cbotfl: 1,
        cbapdt: getDateTimeStr1(new Date()),
        cbcflg: null,
        cbpkbn: 3,
        cbncdy: '',
        cboccp: null,
        visitDate: null,
        visitTime: '',
        visitChk: false,
        cbsic1:'',
        cbrte1: 100,
        cbsic2:'',
        cbrte2: null,
        cbsic3:'',
        cbrte3: null,
        cbsic4:'',
        cbrte4: null,
        cbsic5:'',
        cbrte5: null,
        cbsic6:'',
        cbrte6: null,
        cbsic7:'',
        cbrte7: null,
        cbsic8:'',
        cbrte8: null,
        cbsic9:'',
        cbrte9: null,
        cbsic10:'',
        cbrte10: null,
        inheritContent:'',
        paymentNote:'',
        invoiceIssue: null,
        pdfDate: '',
        sendDate: '',
        mbPhoneNum: '',
        mbMailAddress: '',
        interview: null,
        expectedNum: null,
        bannerSearch1: '',
        bannerSearch2: '',
        remark: '',
        saasCmmt: '',
        contractorNm: '',
        mb1MailAddress: 'keiri_pp@p-partners.co.jp',
        mb1Name: '経理',
        mb1CompanyName: '株式会社プレシャスパートナーズ',
        mb2MailAddress: '',
        mb2Name: '',
        mb2CompanyName: '',
        mb3MailAddress: '',
        mb3Name: '',
        mb3CompanyName: '',
        mb4MailAddress: '',
        mb4Name: '',
        mb4CompanyName: '',
        gy1MailAddress: '',
        gy1Name: '',
        gy2MailAddress: '',
        gy2Name: '',
        gy3MailAddress: '',
        gy3Name: '',
        gy4MailAddress: '',
        gy4Name: '',
        appFlow: null,
        cbgaic: null,
        classKbn: null,
        tmpClassKbn: null,
        jobCategory: null,
        scndAgencyChk: false,
        scndAgency: '',
        scndMbCd: null,
        serviceList: [],
        recruitingList: [],
      },
      serviceForm: {
        idx: 0,
        mode: 0,
        addServiceVisible: false,
        cdsvtpsr: null,
        cdsvtp: null,
        cdsvtpNm: null,
        cdsvnmsr: null,
        cdsvnm: null,
        cdsvnmNm: null,
        cdplcd: null,
        cdplnm: null,
        cdpbar: null,
        cdpbarNm: null,
        cdlti1: null,
        cdlti2: null,
        cdlti1Nm: null,
        cdlti2Nm: null,
        cdcpn1: null,
        cdcpn2: null,
        cdcpn1Nm: null,
        cdcpn2Nm: null,
        cdoccp: null,
        cdterm: null,
        cdstrd: null,
        cdendd: null,
        cdbcmt: '',
        cdslcn: null,
        cdlprc: null,
        cddcnt: null,
        cdstrc: null,
        cdsprc: 0,
        cdcost: 0,
        cdprft: 0,
        cdblcn: null,
        blblpd: null,
        bldppd: null,
        profit: null,
        profitDiv: null,
        cdocmt: '',
      },
      recruitingForm: {
        idx: 0,
        mode: 0,
        addVisible: false,
        copyFlg: 1,
        advtid: "",
        advtkj: "",
        adadkj: "",
        address1: "",
        address2: "",
        address3: "",
        advtel: "",
        adbzty: null,
        adbztyNm: "",
        adcmmt: "",
        adstcd: 0,
      },
      okForm: {
        id: null,
        isVisible: false,
      },
      businessForm: {
        isVisible: false,
        bizno: "",
      },
      cdsvtpList: [],
      cdsvtpsrList: [],
      cdsvnmList: [],
      cdsvnmsrList: [],
      cdplnmList: [],
      printAreaList: [],
      listInfo1List: [],
      listInfo2List: [],
      cdcpnList: [],
      adbztyList: [],
      rules: {
        corporateName: [
          { required: true, message: "法人名を入力してください。", trigger: "blur" }
        ],
        representative: [
          { required: true, message: "代表者名を入力してください。", trigger: "blur" }
        ],
        clclid: [
          { pattern: /^C[0-9]{6}$/, message: "「C」＋数字6桁の形式です。", trigger: "blur" }
        ],
        cllcid: [
          { required: true, message: "拠点名を入力してください。", trigger: "blur" }
        ],
        clzipc: [
          { required: true, message: "郵便番号を入力してください。", trigger: "blur" },
          { pattern: /^[0-9]{3}-[0-9]{4}$/, message: "「000-0000」表記で入力してください。", trigger: "blur" }
        ],
        clnmkj: [
          { required: true, message: "本社名（漢字）を入力してください。", trigger: "blur" },
          // { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        clnmkn: [
          { required: true, message: "本社名（フリガナ）を入力してください。", trigger: "blur" },
          //{ pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        cladkj1: [
          { required: true, message: "都道府県を入力してください。", trigger: "blur" }
        ],
        cladkj2: [
          { required: true, message: "市区町村を入力してください。", trigger: "blur" }
        ],
        cladkj3: [
          { required: true, message: "番地・建物名以下を入力してください。", trigger: "blur" }
        ],
        clttel: [
          { required: true, message: "TELを入力してください。", trigger: "blur" },
          { required: false, validator: checkPhoneNumber, trigger: "blur" }
        ],
        clmnkj: [
          { required: true, message: "担当者（漢字）を入力してください。", trigger: "blur" },
          { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
          
        ],
        clmnkn: [
          { required: true, message: "担当者（フリガナ）を入力してください。", trigger: "blur" },
          { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        clbdnm: [
          { required: true, message: "媒体名を入力してください。", trigger: "blur" }
        ],
        // clmnid: [
        //   { required: false, validator: validateClbdnm, trigger: "blur" },
        //   { pattern: /^[0-9a-zA-Z]*$/, message: "半角英数字で入力してください。", trigger: "blur" }
        // ],
        // clmnpw: [
        //   { required: false, validator: validateClbdnm, trigger: "blur" },
        //   { pattern: /^[0-9a-zA-Z]*$/, message: "半角英数字で入力してください。", trigger: "blur" }
        // ],
        clcomp: [
          // { required: false, validator: validateClcomp, trigger: "blur" },
          { required: true, message: "CompNoを入力してください。", trigger: "blur" },
          { pattern: /^[0-9a-zA-Z\-]*$/, message: "半角英数字で入力してください。", trigger: "blur" }
        ],
        clmskj: [
          { required: true, message: "管理画面名を入力してください。", trigger: "blur" },
          // { required: false, validator: validateClbdnm, trigger: "blur" },
          // { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        clmskn: [
          // { required: false, validator: validateClbdnm, trigger: "blur" },
          { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        clplm4: [
          { required: true, message: "備考を入力してください。", trigger: "blur" }
        ],
        blcbid: [
          //{ pattern: /^B[0-9]{2}$/, message: "「B」＋数字2桁の形式です。", trigger: "blur" }
          { required: false, validator: validateBlcbid, trigger: "blur" }
        ],
        copyFlg: [
          { required: true, message: "顧客情報と同様を入力してください。", trigger: "blur" }
        ],
        blnmkj: [
          { required: true, message: "請求先名（漢字）を入力してください。", trigger: "blur" },
          // { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        blnmkn: [
          { required: true, message: "請求先名（フリガナ）を入力してください。", trigger: "blur" },
          { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        blzipc: [
          { required: true, message: "郵便番号を入力してください。", trigger: "blur" },
          { pattern: /^[0-9a-zA-Z\-]*$/, message: "半角英数字で入力してください。", trigger: "blur" }
        ],
        blakj1: [
          { required: true, message: "送付先住所（漢字）を入力してください。", trigger: "blur" }
        ],
        blakj2: [
          { required: true, message: "ビル名（漢字）を入力してください。", trigger: "blur" }
        ],
        recipientName: [
          { required: true, message: "お宛名を入力してください。", trigger: "blur" }
        ],
        bltflg: [
          { required: true, message: "振込手数料を入力してください。", trigger: "blur" },
          { pattern: /^[0-9a-zA-Z]*$/, message: "半角英数字で入力してください。", trigger: "blur" }
        ],
        blpyr1: [
          { required: true, message: "振込名義を入力してください。", trigger: "blur" },
          { pattern: /^([\uFF61-\uFF9F]|[\x00-\xff]|\x20)+$/, message: "半角カタカナで入力してください。", trigger: "blur" }
        ],
        cbapdt: [
          { required: true, message: "申込日を入力してください。", trigger: "blur" }
        ],
        cbcflg: [
          { required: true, message: "新・継続区分を入力してください。", trigger: "blur" }
        ],
        cboccp: [
          { required: true, message: "職種を入力してください。", trigger: "blur" }
        ],
        visitDate: [
          { required: false, validator: validateVisitDate, trigger: "blur" }
        ],
        visitTime: [
          { required: false, validator: validateVisitDate, trigger: "change" }
        ],
        visitChk: [
          { required: false, validator: validateVisitDate, trigger: "change" }
        ],
        scndAgency: [
          { required: true, message: "二次代理店を入力してください。", trigger: "blur" }
        ],
        scndMbCd: [
          { required: true, message: "担当者を入力してください。", trigger: "blur" }
        ],
        sectionId11: [
          { required: true, message: "担当者（１）の部署1を入力してください。", trigger: "blur" }
        ],
        sectionId12: [
          { required: true, message: "担当者（１）の部署2を入力してください。", trigger: "blur" }
        ],
        cbsic1: [
          { required: true, message: "担当者（１）の営業担当者を入力してください。", trigger: "blur" }
        ],
        cbrte1: [
          { required: true, message: "担当者（１）の利益分配率を入力してください。", trigger: "blur" },
          { pattern: /^(0|[0-9][0-9]?|100)$/, message: "「0」から「100」までの整数を入力してください。", trigger: "blur" }
        ],
        cbrte2: [
          { pattern: /^(0|[0-9][0-9]?|100)$/, message: "「0」から「100」までの整数を入力してください。", trigger: "blur" }
        ],
        cbrte3: [
          { pattern: /^(0|[0-9][0-9]?|100)$/, message: "「0」から「100」までの整数を入力してください。", trigger: "blur" }
        ],
        cbrte4: [
          { pattern: /^(0|[0-9][0-9]?|100)$/, message: "「0」から「100」までの整数を入力してください。", trigger: "blur" }
        ],
        cbrte5: [
          { pattern: /^(0|[0-9][0-9]?|100)$/, message: "「0」から「100」までの整数を入力してください。", trigger: "blur" }
        ],
        cbrte6: [
          { pattern: /^(0|[0-9][0-9]?|100)$/, message: "「0」から「100」までの整数を入力してください。", trigger: "blur" }
        ],
        cbrte7: [
          { pattern: /^(0|[0-9][0-9]?|100)$/, message: "「0」から「100」までの整数を入力してください。", trigger: "blur" }
        ],
        cbrte8: [
          { pattern: /^(0|[0-9][0-9]?|100)$/, message: "「0」から「100」までの整数を入力してください。", trigger: "blur" }
        ],
        cbrte9: [
          { pattern: /^(0|[0-9][0-9]?|100)$/, message: "「0」から「100」までの整数を入力してください。", trigger: "blur" }
        ],
        cbrte10: [
          { pattern: /^(0|[0-9][0-9]?|100)$/, message: "「0」から「100」までの整数を入力してください。", trigger: "blur" }
        ],
        cbgaic: [
          { required: true, message: "庶務担当者を入力してください。", trigger: "blur" }
        ],
        mb1Name: [
          { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        mb1CompanyName: [
          { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        mb2Name: [
          { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        mb2CompanyName: [
          { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        mb3Name: [
          { required: true, message: "担当者2の氏名を入力してください。", trigger: "blur" },
          { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        mb3CompanyName: [
          { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        mb4Name: [
          { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        mb4CompanyName: [
          { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        gy1Name: [
          { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        gy2Name: [
          { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        gy3Name: [
          { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        gy4Name: [
          { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        // scndMbNm: [
        //   { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        // ],
        clickj: [
          { required: false, validator: validateClickj, trigger: "blur" },
          { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        clickn: [
          // { required: false, validator: validateClickn, trigger: "blur" },
          { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        clplm2: [
          { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        cltfax:  [
          { pattern: /^[0-9\-]+$/, message: "Faxを正しく入力してください。", trigger: "blur" }
        ],
        mbPhoneNum:  [
          { pattern: /^[0-9\-]+$/, message: "ご担当者様電話番号を正しく入力してください。", trigger: "blur" }
        ],
        blplm7: [
          // { required: true, message: "メールアドレス①を入力してください。", trigger: "blur" },
          { pattern: /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, message: "メールアドレス①を正しく入力してください。", trigger: "blur" }
        ],
        blplm8: [
          { pattern: /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, message: "メールアドレス②を正しく入力してください。", trigger: "blur" }
        ],
        blplm9: [
          { pattern: /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, message: "メールアドレス③を正しく入力してください。", trigger: "blur" }
        ],
        blplm10: [
          { pattern: /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, message: "メールアドレス④を正しく入力してください。", trigger: "blur" }
        ],
        mbMailAddress: [
          { pattern: /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, message: "ご担当者様メールアドレスを正しく入力してください。", trigger: "blur" }
        ],
        mb1MailAddress: [
          { pattern: /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, message: "担当者1のメアドを正しく入力してください。", trigger: "blur" }
        ],
        mb3MailAddress: [
          { required: true, message: "担当者2のメアドを入力してください。", trigger: "blur" },
          { pattern: /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, message: "担当者2のメアドを正しく入力してください。", trigger: "blur" }
        ],
        mb4MailAddress: [
          { pattern: /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, message: "担当者3のメアドを正しく入力してください。", trigger: "blur" }
        ],
        gy1MailAddress: [
          { pattern: /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, message: "共有先1のメアドを正しく入力してください。", trigger: "blur" }
        ],
        gy2MailAddress: [
          { pattern: /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, message: "共有先2のメアドを正しく入力してください。", trigger: "blur" }
        ],
        gy3MailAddress: [
          { pattern: /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, message: "共有先3のメアドを正しく入力してください。", trigger: "blur" }
        ],
        gy4MailAddress: [
          { pattern: /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, message: "共有先4のメアドを正しく入力してください。", trigger: "blur" }
        ],
        appFlow: [
          { required: false, validator: validateAppFlow, trigger: "blur" }
        ],
      },
      serviceRules: {
        cdsvtpsr: [
          { required: true, message: "種別を入力してください。", trigger: "blur" }
        ],
        cdsvtp: [
          { required: true, message: "種別を入力してください。", trigger: "blur" }
        ],
        cdsvnmsr: [
          { required: true, message: "サービス名を入力してください。", trigger: "blur" }
        ],
        cdsvnm: [
          { required: true, message: "サービス名を入力してください。", trigger: "blur" }
        ],
        cdplcd: [
          { required: true, message: "プラン名を入力してください。", trigger: "blur" }
        ],
        cdpbar: [
          { required: true, message: "掲載エリアを入力してください。", trigger: "blur" }
        ],
        cdterm: [
          { required: true, message: "掲載期間を入力してください。", trigger: "blur" },
          { pattern: /^\d{1,3}$/, message: "最大数字3桁で入力してください。", trigger: "blur" }
        ],
        cdstrd: [
          { required: true, message: "掲載開始日を入力してください。", trigger: "blur" }
        ],
        cdendd: [
          { required: true, message: "掲載終了日を入力してください。", trigger: "blur" }
        ],
        cdslcn: [
          { required: true, message: "数量を入力してください。", trigger: "blur" },
          { pattern: /^\d{1,8}$/, message: "最大数字8桁で入力してください。", trigger: "blur" }
        ],
        cdlprc: [
          { required: true, message: "単価を入力してください。", trigger: "blur" },
          { pattern: /^\d{1,8}$/, message: "最大数字8桁で入力してください。", trigger: "blur" }
        ],
        cddcnt: [
          { required: true, message: "値引きを入力してください。", trigger: "blur" },
          { pattern: /^\d{1,8}$/, message: "最大数字8桁で入力してください。", trigger: "blur" }
        ],
        cdstrc: [
          { required: true, message: "小計を入力してください。", trigger: "blur" },
          { pattern: /^\d{1,8}$/, message: "最大数字8桁で入力してください。", trigger: "blur" }
        ],
        cdsprc: [
          { required: true, message: "合計額を入力してください。", trigger: "blur" },
          { pattern: /^\d{1,8}$/, message: "最大数字8桁で入力してください。", trigger: "blur" }
        ],
        cdcost: [
          { required: true, message: "原価を入力してください。", trigger: "blur" },
          { pattern: /^\d{1,8}$/, message: "最大数字8桁で入力してください。", trigger: "blur" }
        ],
        cdprft: [
          { required: true, message: "利益を入力してください。", trigger: "blur" },
          { pattern: /^\d{1,8}$/, message: "最大数字8桁で入力してください。", trigger: "blur" }
        ],
        cdblcn: [
          { required: true, message: "請求・支払　分割数を入力してください。", trigger: "blur" },
          { pattern: /^\d{1,3}$/, message: "最大数字3桁で入力してください。", trigger: "blur" }
        ],
        blblpd: [
          { required: true, message: "請求日を入力してください。", trigger: "blur" }
        ],
        bldppd: [
          { required: true, message: "支払日を入力してください。", trigger: "blur" }
        ],
        profit: [
          { required: true, message: "営業利益計上月を入力してください。", trigger: "blur" }
        ],
        profitDiv: [
          { required: true, message: "営業利益分割数を入力してください。", trigger: "blur" },
          { pattern: /^([1-9]|10|11|12)$/, message: "「1」から「12」までの整数を入力してください。", trigger: "blur" }
        ],
      },
      recruitingRules: {
        advtid: [
          { pattern: /^A[0-9]{2}$/, message: "「A」＋数字2桁の形式です。", trigger: "blur" }
        ],
        advtkj: [
          { required: true, message: "店舗名（漢）を入力してください。", trigger: "blur" },
          // { pattern: /[^\x01-\x7E]$/, message: "全角文字で入力してください。", trigger: "blur" }
        ],
        address1: [
          { required: true, message: "都道府県を入力してください。", trigger: "blur" }
        ],
        address2: [
          { required: true, message: "市区町村を入力してください。", trigger: "blur" }
        ],
        address3: [
          { required: true, message: "番地・建物名以下を入力してください。", trigger: "blur" }
        ],
        advtel: [
          //{ required: true, message: "店舗TELを入力してください。", trigger: "blur" },
          { required: false, validator: checkPhoneNumber2, trigger: "blur" }
        ],
        adbzty: [
          { required: true, message: "業種を入力してください。", trigger: "blur" }
        ]
      },
      businessRules: {
        bizno: [
          { required: true, message: "仕事NOを入力してください。", trigger: "blur" }
        ]
      }
    };
  },
  mounted() {
    getKbnList({
      kbType: "K000"
    }).then((response) => {
      this.locationList = response.data;
      if (this.accountInfo.role > 3) {
        groupDetail({
          groupId: this.accountInfo.groupId
        }).then(response => {
          getSshPayee({
            customerId: this.form.clclid,
            payeeId: response.data.bimsBlcbid,
            role: this.accountInfo.role
          }).then((response) => {
            this.form.payeeMode = 1;
            this.form.blclid = response.data.blclid;
            this.form.blcbid = response.data.blcbid;
            this.form.blnmkj = response.data.blnmkj + response.data.blnkj2 + response.data.blnkj3;
            this.form.blnmkn = response.data.blnmkn + response.data.blnkn2 + response.data.blnkn3;
            this.form.blzipc = response.data.blzipc;
            this.form.blakj1 = response.data.blakj1;
            this.form.blakj2 = response.data.blakj2;
            this.form.recipientName = this.form.blnmkj;
            this.form.blltel = response.data.blltel;
            this.form.bllfax = response.data.bllfax;
            this.form.blcmmt = response.data.blcmmt;
            this.form.blstcd = response.data.blstcd;
            this.form.bltflg = response.data.bltflg;
            this.form.blpyr1 = response.data.blpyr1;
            this.form.blplm1 = response.data.blplm1;
            this.form.blplm7 = response.data.blplm7;
            this.form.blplm8 = response.data.blplm8;
            this.form.blplm9 = response.data.blplm9;
            this.form.blplm10 = response.data.blplm10;
            if (response.data.blplm7 != null && response.data.blplm7 != "") {
              this.form.blplmMode = 1;
            } else {
              this.form.blplmMode = 0;
            }
          }).catch((err) => {
            this.$router.push(ROUTER_PATH.APPLY_CENTER);
          });

          if (this.$route.query.id == null || this.$route.query.id == "") {
            //仅在新规的场合做该处理
            for (var i = 0; i < this.locationList.length; i++) {
              var selData = this.locationList[i];
              if (selData.kbName === "二次代理店") {
                this.form.cllcid = selData.kbCode;
                break;
              }
            }

            if (response.data.scndAgencyNm && response.data.scndAgencyNm != "") {
              this.form.scndAgencyChk = true;
              this.form.scndAgency = response.data.scndAgencyNm;
            }
            if (response.data.ppCbsic1 && response.data.ppCbsic1 != "") {
              this.form.cbsic1 = response.data.ppCbsic1;
            } else {
              this.$message({
                message: "営業担当者情報を取得できません。",
                type: "error",
              });
              this.$router.push(ROUTER_PATH.APPLY_CENTER);
            }
          }
        });
      }
    });
    getKbnList({
      kbType: "K005"
    }).then((response) => {
      this.cbcflgOptions = response.data;
    });
    // getKbnList({
    //   kbType: "K006"
    // }).then((response) => {
    //   console.log(response.data);
    // });
    getKbnList({
      kbType: "K009"
    }).then((response) => {
      this.section1List = response.data;
    });
    getManagerList({
    }).then((response) => {
      this.generalManagerOptions = response.data;
    });
    getLocalKbnList({
      kbType: "SK009"
    }).then((response) => {
      this.classificationOptions = response.data;
    });
    getLocalKbnList({
      kbType: "SK012"
    }).then((response) => {
      this.serviceJobList = response.data;
    });

    this.queryScndMbList(null);
  },
  created: function () {
    var mcltid = this.$route.query.id;
    this.form.clplm3 = ((this.accountInfo.role <= 3 && this.form.gropid == null ) || this.form.gropid <= 3) ? 0 : 1;
    if (mcltid && mcltid != "") {
        customerDetail({
          id: mcltid,
          groupId: this.accountInfo.groupId
        }).then((response) => {
          var cladkjList = getAddressList(response.data.customerVo.cladkj);
          this.form = {
            corporateName: response.data.customerVo.clnmkj,
            representative: response.data.customerVo.clickj, 
            mbllid: response.data.payeeVo.mbllid,
            tcrbid: response.data.contractVo.tcrbid,
            casdid: response.data.caseDataVo.id,
            gropid: response.data.customerVo.gropid,
            clcrpg: response.data.customerVo.clcrpg,
            clclid: response.data.customerVo.clclid,
            cllcid: !response.data.customerVo.cllcid ? null : response.data.customerVo.cllcid + "",
            clzipc: response.data.customerVo.clzipc,
            clmnkj: response.data.customerVo.clmnkj,
            clmnkn: response.data.customerVo.clmnkn.trim(),
            clmnid: response.data.customerVo.clmnid,
            clnmkj: response.data.customerVo.clnmkj,
            clnmkn: response.data.customerVo.clnmkn,
            cladkj1: cladkjList[0],
            cladkj2: cladkjList[1],
            cladkj3: cladkjList[2],
            cladkn: response.data.customerVo.cladkn,
            clbdnm: response.data.customerVo.clbdnm,
            clmnpw: response.data.customerVo.clmnpw,
            clmnps: response.data.customerVo.clmnps,
            clmskj: response.data.customerVo.clmskj,
            clmskn: response.data.customerVo.clmskn,
            clcomp: response.data.customerVo.clcomp,
            clickj: response.data.customerVo.clickj,
            clickn: response.data.customerVo.clickn,
            clcmmt: response.data.customerVo.clcmmt,
            clttel: response.data.customerVo.clttel,
            cltfax: response.data.customerVo.cltfax,
            clicps: response.data.customerVo.clicps,
            clplm1: response.data.customerVo.clplm1,
            clplm2: response.data.customerVo.clplm2,
            clplm3: response.data.customerVo.clplm3,
            clplm4: response.data.customerVo.clplm4,
            clstcd: response.data.customerVo.clstcd,
            prcsst: response.data.customerVo.prcsst,
            blclid: response.data.payeeVo.blclid,
            blcbid: response.data.payeeVo.blcbid,
            copyFlg: 1,
            blnmkj: response.data.payeeVo.blnmkj,
            blnmkn: response.data.payeeVo.blnmkn,
            blzipc: response.data.payeeVo.blzipc,
            blakj1: response.data.payeeVo.blakj1,
            blakj2: response.data.payeeVo.blakj2,
            recipientName: response.data.caseDataVo.recipientName,
            blltel: response.data.payeeVo.blltel,
            bllfax: response.data.payeeVo.bllfax,
            blcmmt: response.data.payeeVo.blcmmt,
            blstcd: response.data.payeeVo.blstcd + "",
            bltflg: response.data.payeeVo.bltflg,
            pmtFlg: response.data.caseDataVo.pmtFlg,
            blpyr1: response.data.payeeVo.blpyr1,
            blplm1: response.data.payeeVo.blplm1,
            blplm7: response.data.payeeVo.blplm7,
            blplm8: response.data.payeeVo.blplm8,
            blplm9: response.data.payeeVo.blplm9,
            blplm10: response.data.payeeVo.blplm10,
            sectionId11: '',
            sectionId12: '',
            sectionId21: '',
            sectionId22: '',
            sectionId31: '',
            sectionId32: '',
            sectionId41: '',
            sectionId42: '',
            sectionId51: '',
            sectionId52: '',
            sectionId61: '',
            sectionId62: '',
            sectionId71: '',
            sectionId72: '',
            sectionId81: '',
            sectionId82: '',
            sectionId91: '',
            sectionId92: '',
            sectionId101: '',
            sectionId102: '',
            cbotfl: response.data.contractVo.cbotfl,
            cbapdt: response.data.contractVo.cbapdt,
            cbcflg: !response.data.contractVo.cbcflg ? null : response.data.contractVo.cbcflg + "",
            cbpkbn: response.data.contractVo.cbpkbn,
            cbncdy: response.data.contractVo.cbncdy,
            cboccp: !response.data.contractVo.cboccp ? null : response.data.contractVo.cboccp + "",
            visitDate: response.data.caseDataVo.visitDate,
            visitTime: response.data.caseDataVo.visitTime,
            visitChk: response.data.caseDataVo.visitChk,
            cbsic1: response.data.contractVo.cbsic1,
            cbrte1: response.data.contractVo.cbrte1,
            cbsic2: response.data.contractVo.cbsic2,
            cbrte2: response.data.contractVo.cbrte2,
            cbsic3: response.data.contractVo.cbsic3,
            cbrte3: response.data.contractVo.cbrte3,
            cbsic4: response.data.contractVo.cbsic4,
            cbrte4: response.data.contractVo.cbrte4,
            cbsic5: response.data.contractVo.cbsic5,
            cbrte5: response.data.contractVo.cbrte5,
            cbsic6: response.data.contractVo.cbsic6,
            cbrte6: response.data.contractVo.cbrte6,
            cbsic7: response.data.contractVo.cbsic7,
            cbrte7: response.data.contractVo.cbrte7,
            cbsic8: response.data.contractVo.cbsic8,
            cbrte8: response.data.contractVo.cbrte8,
            cbsic9: response.data.contractVo.cbsic9,
            cbrte9: response.data.contractVo.cbrte9,
            cbsic10: response.data.contractVo.cbsic10,
            cbrte10: response.data.contractVo.cbrte10,
            inheritContent: response.data.caseDataVo.inheritContent,
            paymentNote: response.data.caseDataVo.paymentNote,
            invoiceIssue: response.data.caseDataVo.invoiceIssue,
            pdfDate: response.data.caseDataVo.pdfDate,
            sendDate: response.data.caseDataVo.sendDate,
            mbPhoneNum: response.data.caseDataVo.mbPhoneNum,
            mbMailAddress: response.data.caseDataVo.mbMailAddress,
            interview: response.data.caseDataVo.interview,
            expectedNum: response.data.caseDataVo.expectedNum,
            bannerSearch1: response.data.caseDataVo.bannerSearch1,
            bannerSearch2: response.data.caseDataVo.bannerSearch2,
            remark: response.data.caseDataVo.remark,
            saasCmmt: response.data.caseDataVo.saasCmmt,
            contractorNm: response.data.customerVo.clnmkj,
            mb1MailAddress: response.data.caseDataVo.mb1MailAddress,
            mb1Name: response.data.caseDataVo.mb1Name,
            mb1CompanyName: response.data.caseDataVo.mb1CompanyName,
            mb2MailAddress: response.data.caseDataVo.mb2MailAddress,
            mb2Name: response.data.caseDataVo.mb2Name,
            mb2CompanyName: response.data.caseDataVo.mb2CompanyName,
            mb3MailAddress: response.data.caseDataVo.mb3MailAddress,
            mb3Name: response.data.caseDataVo.mb3Name,
            mb3CompanyName: response.data.caseDataVo.mb3CompanyName,
            mb4MailAddress: response.data.caseDataVo.mb4MailAddress,
            mb4Name: response.data.caseDataVo.mb4Name,
            mb4CompanyName: response.data.caseDataVo.mb4CompanyName,
            gy1MailAddress: response.data.caseDataVo.gy1MailAddress,
            gy1Name: response.data.caseDataVo.gy1Name,
            gy2MailAddress: response.data.caseDataVo.gy2MailAddress,
            gy2Name: response.data.caseDataVo.gy2Name,
            gy3MailAddress: response.data.caseDataVo.gy3MailAddress,
            gy3Name: response.data.caseDataVo.gy3Name,
            gy4MailAddress: response.data.caseDataVo.gy4MailAddress,
            cbgaic: response.data.contractVo.cbgaic,
            gy4Name: response.data.caseDataVo.gy4Name,
            appFlow: response.data.caseDataVo.appFlow,
            classKbn: !response.data.caseDataVo.classKbn ? null : response.data.caseDataVo.classKbn + "",
            tmpClassKbn: !response.data.caseDataVo.classKbn ? null : response.data.caseDataVo.classKbn + "",
            jobCategory: !response.data.caseDataVo.jobCategory ? null : response.data.caseDataVo.jobCategory + "",
            scndAgencyChk: response.data.caseDataVo.scndAgencyChk,
            scndAgency: response.data.caseDataVo.scndAgency,
            scndMbCd: response.data.caseDataVo.scndMbCd,
            serviceList: response.data.serviceList,
            recruitingList: response.data.recruitingList,
          }
          this.businessForm.bizno = response.data.customerVo.bizno

          //コピーの場合、案件状態の初期化
          if (this.$route.query.status == 2) {
            this.form.cbapdt = getDateTimeStr1(new Date());
            this.form.prcsst = null;
            this.form.appFlow = null;
            //印刷区分
            if (this.form.cbpkbn == 1 || this.form.cbpkbn == 2) {
              this.form.cbpkbn = 3;
            }

            //重新获取支付日判定条件
            if (this.form.blstcd === "1") {
              getSshPayee({
                customerId: this.form.clclid,
                payeeId: this.form.blcbid,
                role: this.accountInfo.role
              }).then((response) => {
                this.form.blplm1 = response.data.blplm1;
                //追加处理：循环serivcelist重新计算支付日
                if (this.form.serviceList && this.form.serviceList.length > 0) {
                  for (var i = 0; i < this.form.serviceList.length; i++) {
                    var serData = this.form.serviceList[i];
                    var tempCdsvnmsrList = [];
                    getSubLocalKbnList({
                      kbType: "SK001",
                      kbCode: serData.cdsvtpsr,
                      subKbType: "SK002"
                    }).then((response) => {
                      tempCdsvnmsrList = response.data;
                    });
                    this.calcPayDate(tempCdsvnmsrList, serData);
                  }
                }
              });
            } else {
              //追加处理：循环serivcelist重新计算支付日
              if (this.form.serviceList && this.form.serviceList.length > 0) {
                for (var i = 0; i < this.form.serviceList.length; i++) {
                  var serData = this.form.serviceList[i];
                  var tempCdsvnmsrList = [];
                  getSubLocalKbnList({
                    kbType: "SK001",
                    kbCode: serData.cdsvtpsr,
                    subKbType: "SK002"
                  }).then((response) => {
                    tempCdsvnmsrList = response.data;
                  });
                  this.calcPayDate(tempCdsvnmsrList, serData);
                }
              }
            }
          }
          if (this.form.clcomp) {
            this.tmpCompNo = this.form.clcomp;
          }
          if (this.form.blstcd == "1") {
            this.form.payeeMode = 1;
          }

          if (this.form.cbsic1 && this.form.cbsic1 != "") {
            getParentList({
              emplid: this.form.cbsic1
            }).then((response) => {
              this.form.sectionId11 = response.data.sectionId1;
              this.section12List = response.data.parentList;
              this.form.sectionId12 = response.data.sectionId2;
              this.member1List = response.data.memberList;
            });
          }

          if (this.form.cbsic2 && this.form.cbsic2 != "") {
            getParentList({
              emplid: this.form.cbsic2
            }).then((response) => {
              this.form.sectionId21 = response.data.sectionId1;
              this.section22List = response.data.parentList;
              this.form.sectionId22 = response.data.sectionId2;
              this.member2List = response.data.memberList;
            });
          }
          if (this.form.cbsic3 && this.form.cbsic3 != "") {
            getParentList({
              emplid: this.form.cbsic3
            }).then((response) => {
              this.form.sectionId31 = response.data.sectionId1;
              this.section32List = response.data.parentList;
              this.form.sectionId32 = response.data.sectionId2;
              this.member3List = response.data.memberList;
            });
          }
          if (this.form.cbsic4 && this.form.cbsi4 != "") {
            getParentList({
              emplid: this.form.cbsic4
            }).then((response) => {
              this.form.sectionId41 = response.data.sectionId1;
              this.section42List = response.data.parentList;
              this.form.sectionId42 = response.data.sectionId2;
              this.member4List = response.data.memberList;
            });
          }

          if (this.form.cbsic5 && this.form.cbsic5 != "") {
            getParentList({
              emplid: this.form.cbsic5
            }).then((response) => {
              this.form.sectionId51 = response.data.sectionId1;
              this.section52List = response.data.parentList;
              this.form.sectionId52 = response.data.sectionId2;
              this.member5List = response.data.memberList;
            });
          }
          if (this.form.cbsic6 && this.form.cbsic6 != "") {
            getParentList({
              emplid: this.form.cbsic6
            }).then((response) => {
              this.form.sectionId61 = response.data.sectionId1;
              this.section62List = response.data.parentList;
              this.form.sectionId62 = response.data.sectionId2;
              this.member6List = response.data.memberList;
            });
          }
          if (this.form.cbsic7 && this.form.cbsic7 != "") {
            getParentList({
              emplid: this.form.cbsic7
            }).then((response) => {
              this.form.sectionId71 = response.data.sectionId1;
              this.section72List = response.data.parentList;
              this.form.sectionId72 = response.data.sectionId2;
              this.member7List = response.data.memberList;
            });
          }

          if (this.form.cbsic8 && this.form.cbsic8 != "") {
            getParentList({
              emplid: this.form.cbsic8
            }).then((response) => {
              this.form.sectionId81 = response.data.sectionId1;
              this.section82List = response.data.parentList;
              this.form.sectionId82 = response.data.sectionId2;
              this.member8List = response.data.memberList;
            });
          }
          if (this.form.cbsic9 && this.form.cbsic9 != "") {
            getParentList({
              emplid: this.form.cbsic9
            }).then((response) => {
              this.form.sectionId91 = response.data.sectionId1;
              this.section92List = response.data.parentList;
              this.form.sectionId92 = response.data.sectionId2;
              this.member9List = response.data.memberList;
            });
          }
          if (this.form.cbsic10 && this.form.cbsic10 != "") {
            getParentList({
              emplid: this.form.cbsic10
            }).then((response) => {
              this.form.sectionId101 = response.data.sectionId1;
              this.section102List = response.data.parentList;
              this.form.sectionId102 = response.data.sectionId2;
              this.member10List = response.data.memberList;
            });
          }
          if (this.form.classKbn) {
            getSubLocalKbnList({
              kbType: "SK009",
              kbCode: this.form.classKbn,
              subKbType: "SK013"
            }).then((response) => {
              this.jobList = response.data;

              var nFlg1 = 0;
              var nFlg2 = 0;
              for (var i = 0; i < this.jobList.length; i++) {
                var selData = this.jobList[i];
                if (selData.kbCode === this.form.jobCategory) {
                  nFlg1 = 1;
                }
                if (selData.kbCode === this.form.cboccp) {
                  nFlg2 = 1;
                }
              }
              if (nFlg1 === 0) this.form.jobCategory = null;
              if (nFlg2 === 0) {
                this.form.cboccp = null;
                this.$message({
                  message: "掲載区分と職種を再度ご確認下さい。",
                  type: "error",
                });
              }
            });
          }
        });
    } else {
      if (this.accountInfo.role == 4) {
        this.form.cbgaic = "kamiyama"
      }
    }
  },
  computed: {
    ...mapState(["accountInfo"])
  },
  methods: {
    formatStrDate(cdstrd) {
      var myDate = new Date(cdstrd);
      var year = myDate.getFullYear();
      var month = zeroPad(myDate.getMonth() + 1);
      var day = zeroPad(myDate.getDate());
      return  year + "/" + month + "/" + day;
    },
    formatEndDate(cdendd) {
      var myDate = new Date(cdendd);
      var year = myDate.getFullYear();
      var month = zeroPad(myDate.getMonth() + 1);
      var day = zeroPad(myDate.getDate());
      return  year + "/" + month + "/" + day;
    },
    queryScndMbList(keyword) {
      getScndMbList({
        keyword: keyword,
        groupId: this.accountInfo.groupId
      }).then((response) => {
        this.scndMbList = response.data;
      });
    },
    searchClZipCd() {
      getZipInfo({
        zipCode: this.form.clzipc.replace("-", "")
      }).then((response) => {
        this.form.cladkj1 = response.data.prefecture;
        this.form.cladkj2 = response.data.address;
      });
    },
    searchBlZipCd() {
      getZipInfo({
        zipCode: this.form.blzipc.replace("-", "")
      }).then((response) => {
        this.form.blakj1 = response.data.prefecture + response.data.address;
      });
    },
    calcPayDate (inputList, inputForm) {
      if (inputList && inputList.length > 0 && inputForm.cdsvnmsr && inputForm.cdsvnmsr != "") {
        for (var i = 0; i < inputList.length; i++) {
          var selData = inputList[i];
          if (selData.kbCode === inputForm.cdsvnmsr) {
            if (selData.kbName.indexOf("Indeed") >= 0) {
              inputForm.blblpd = getLastDate(this.form.cbapdt, 0);
              inputForm.bldppd = getLastDate(this.form.cbapdt, 1);
            } else {
              inputForm.blblpd = getLastDate(inputForm.cdstrd, 0);
              inputForm.bldppd = getLastDate(inputForm.cdstrd, 1);
            }
          }
        }
      } else {
        inputForm.blblpd = getLastDate(inputForm.cdstrd, 0);
        inputForm.bldppd = getLastDate(inputForm.cdstrd, 1);
      }

      if (inputForm.cdstrd) {
        if (inputForm.cdterm) {
          var startDay = new Date(inputForm.cdstrd);
          if (inputForm.cdplnm && inputForm.cdplnm == "リクラクチケット") {
            startDay.setMonth(startDay.getMonth() + 13);
          } else {
            startDay.setDate(startDay.getDate() - 1 + 7*inputForm.cdterm);
          }
          inputForm.cdendd = getDateTimeStr1(startDay);
        }

        if ((this.accountInfo.role > 3 && this.form.gropid == null ) || this.form.gropid > 3) {
          inputForm.profit = getFormatYm(inputForm.cdstrd);
          if (this.form.blplm1 > 0) {
            if (this.form.blplm1 == 1) {
              inputForm.bldppd = getLastDate(inputForm.cdstrd, 1);
            } else if (this.form.blplm1 == 2) {
              inputForm.bldppd = getLastDate(inputForm.cdstrd, 2);
            } else if (this.form.blplm1 == 3) {
              inputForm.bldppd = getNextMonthDay(inputForm.cdstrd, 2, 10);
            } else if (this.form.blplm1 == 4) {
              inputForm.bldppd = getNextMonthDay(inputForm.cdstrd, 2, 20);
            } else if (this.form.blplm1 == 5) {
              inputForm.bldppd = getNextMonthDay(inputForm.cdstrd, 2, 15);
            }
          }
        }
      }
    },
    searchCustomer() {
      getSshCustomer({
        customerId: this.form.clclid,
        compNo: this.form.clcomp,
        groupId: this.accountInfo.groupId
      }).then((response) => {
        var cladkjList = getAddressList(response.data.cladkj);
        this.form.clclid = response.data.clclid;
        this.form.cllcid = response.data.cllcid + "";
        this.form.clzipc = response.data.clzipc;
        this.form.clmnkj = response.data.clmnkj;
        this.form.clmnkn = response.data.clmnkn.trim();
        this.form.clmnid = response.data.clmnid;
        this.form.clnmkj = response.data.clnmkj;
        this.form.clnmkn = response.data.clnmkn;
        this.form.cladkj1 = cladkjList[0];
        this.form.cladkj2 = cladkjList[1];
        this.form.cladkj3 = cladkjList[2];
        this.form.cladkn = response.data.cladkn;
        this.form.clbdnm = response.data.clbdnm;
        this.form.clmnpw = response.data.clmnpw;
        this.form.clmnps = response.data.clmnps;
        this.form.clmskj = response.data.clmskj;
        this.form.clmskn = response.data.clmskn;
        this.form.clcomp = response.data.clcomp;
        this.tmpCompNo = response.data.clcomp;
        this.form.clickj = response.data.clickj;
        this.form.clickn = response.data.clickn;
        this.form.clcmmt = response.data.clcmmt;
        this.form.clttel = response.data.clttel;
        this.form.cltfax = response.data.cltfax;
        this.form.clicps = response.data.clicps;
        this.form.clplm1 = response.data.clplm1;
        this.form.clplm2 = response.data.clplm2;
        this.form.clplm3 = response.data.clplm3;
        this.form.clplm4 = response.data.clplm4;
        this.form.clstcd = response.data.clstcd;
        this.form.prcsst = response.data.prcsst;
        if ((this.accountInfo.role <= 3 && this.form.gropid == null ) || this.form.gropid <= 3) {
          //直販の場合
          //クラウドサイン情報　担当者2　メアド
          this.form.mb3MailAddress = response.data.clplm1;
          //クラウドサイン情報　氏名
          this.form.mb3Name = response.data.clmnkj;
        }
      });
    },
    chkCompNo() {
      if ((this.accountInfo.role > 3 && this.form.gropid == null ) || this.form.gropid > 3) {
        //二次店的场合
        if (this.tmpCompNo &&  this.form.clcomp !== this.tmpCompNo) {
          if (this.$route.query.status == null || this.$route.query.status != 1) {
            this.form.mbllid = null;
          }
          this.form.clstcd = 0;
          this.form.clclid = '';
          this.form.cllcid = null;
          this.form.clzipc = '';
          this.form.clmnkj = '';
          this.form.clmnkn = '';
          this.form.clmnid = '';
          this.form.clnmkj = '';
          this.form.clnmkn = '';
          this.form.cladkj1 = '';
          this.form.cladkj2 = '';
          this.form.cladkj3 = '';
          this.form.cladkj = '';
          this.form.cladkn = '';
          this.form.clbdnm = '';
          this.form.clmnpw = '';
          this.form.clmnps = '';
          this.form.clmskj = '';
          this.form.remark = '';
          // this.form.clcomp = '';
          this.form.clickj = '';
          this.form.clickn = '';
          this.form.clcmmt = '';
          this.form.clttel = '';
          this.form.cltfax = '';
          this.form.clicps = '';
          this.form.clplm1 = '';
          this.form.clplm2 = '';
          this.form.clplm3 = 1;
          this.form.clplm4 = '';
          //清空请求先情报和募集先列表
          // if (this.form.blcbid.startsWith("B")) {
          //   this.form.blstcd = "0";
          //   this.form.payeeMode = 0;
          //   this.form.blplmMode = 0;
          //   this.form.blclid = null;
          //   this.form.blcbid = "";
          //   this.form.blnmkj = null;
          //   this.form.blnmkn = null;
          //   this.form.blzipc = null;
          //   this.form.bltflg = null;
          //   this.form.blakj1 = null;
          //   this.form.blakj2 = null;
          //   this.form.blpyr1 = null;
          //   this.form.pmtFlg = null;
          //   this.form.recipientName = null;
          //   this.form.paymentNote = null;
          //   this.form.blplm1 = 0;
          //   this.form.blplm7 = null;
          //   this.form.blplm8 = null;
          //   this.form.blplm9 = null;
          //   this.form.blplm10 = null; 
          // }
          if (this.form.recruitingList && this.form.recruitingList.length > 0) {
            for (var idx = 0; idx < this.form.recruitingList.length; idx++) {
              if (this.form.recruitingList[idx].advtid) {
                this.form.recruitingList.splice(idx, 1);
                idx--;
              }
            }
          }
        }
      }
    },
    addCustomerFlg() {
      if (this.form.clstcd === 0) {
        if (this.$route.query.status == null || this.$route.query.status != 1) {
          this.form.mbllid = null;
        }
        // this.form.tcrbid = null;
        // this.form.casdid = null;
        this.form.clclid = '';
        this.form.cllcid = null;
        this.form.clzipc = '';
        this.form.clmnkj = '';
        this.form.clmnkn = '';
        this.form.clmnid = '';
        this.form.clnmkj = '';
        this.form.clnmkn = '';
        this.form.cladkj1 = '';
        this.form.cladkj2 = '';
        this.form.cladkj3 = '';
        this.form.cladkj = '';
        this.form.cladkn = '';
        this.form.clbdnm = '';
        this.form.clmnpw = '';
        this.form.clmnps = '';
        this.form.clmskj = '';
        this.form.remark = '';
        this.form.clcomp = '';
        this.form.clickj = '';
        this.form.clickn = '';
        this.form.clcmmt = '';
        this.form.clttel = '';
        this.form.cltfax = '';
        this.form.clicps = '';
        this.form.clplm1 = '';
        this.form.clplm2 = '';
        this.form.clplm3 = 1;
        this.form.clplm4 = '';
        // this.form.prcsst = 0;
        //清空请求先情报和募集先列表
        if (this.form.blcbid.startsWith("B")) {
          this.form.blstcd = "0";
          this.form.payeeMode = 0;
          this.form.blplmMode = 0;
          this.form.blclid = null;
          this.form.blcbid = "";
          this.form.blnmkj = null;
          this.form.blnmkn = null;
          this.form.blzipc = null;
          this.form.bltflg = null;
          this.form.blakj1 = null;
          this.form.blakj2 = null;
          this.form.blpyr1 = null;
          this.form.pmtFlg = null;
          this.form.recipientName = null;
          this.form.paymentNote = null;
          this.form.blplm1 = 0;
          this.form.blplm7 = null;
          this.form.blplm8 = null;
          this.form.blplm9 = null;
          this.form.blplm10 = null; 
        }
        if (this.form.recruitingList && this.form.recruitingList.length > 0) {
          for (var idx = 0; idx < this.form.recruitingList.length; idx++) {
            if (this.form.recruitingList[idx].advtid) {
              this.form.recruitingList.splice(idx, 1);
              idx--;
            }
          }
        }
      }
    },
    handleChangeClplm1() {
      if ((this.accountInfo.role <= 3 && this.form.gropid == null ) || this.form.gropid <= 3) {
        //直販の場合
        //クラウドサイン情報　担当者2　メアド
        this.form.mb3MailAddress = this.form.clplm1;
      }
    },
    handleChangeClmnkj() {
      if ((this.accountInfo.role <= 3 && this.form.gropid == null ) || this.form.gropid <= 3) {
        //直販の場合
        //クラウドサイン情報　氏名
        this.form.mb3Name = this.form.clmnkj;
      }
    },
    handleChangeBlnmkj() {
      if ((this.accountInfo.role <= 3 && this.form.gropid == null ) || this.form.gropid <= 3) {
        //直販の場合
        //クラウドサイン情報　会社名
        this.form.mb3CompanyName = this.form.blnmkj;
      }
    },
    handleChangeCbsic() {
      if ((this.accountInfo.role <= 3 && this.form.gropid == null ) || this.form.gropid <= 3) {
        if (this.form.cbsic1) {
          for (var i = 0; i < this.member1List.length; i++) {
            var selData1 = this.member1List[i];
            if (selData1.kbCode === this.form.cbsic1) {
              this.form.gy1Name = selData1.tmpCmt1;
              this.form.gy1MailAddress = selData1.tmpCmt2;
              break;
            }
          }
        }
        if (this.form.cbsic2) {
          for (var i = 0; i < this.member2List.length; i++) {
            var selData2 = this.member2List[i];
            if (selData2.kbCode === this.form.cbsic2) {
              this.form.gy2Name = selData2.tmpCmt1;
              this.form.gy2MailAddress = selData2.tmpCmt2;
              break;
            }
          }
        }
        if (this.form.cbsic3) {
          for (var i = 0; i < this.member3List.length; i++) {
            var selData3 = this.member3List[i];
            if (selData3.kbCode === this.form.cbsic3) {
              this.form.gy3Name = selData3.tmpCmt1;
              this.form.gy3MailAddress = selData3.tmpCmt2;
              break;
            }
          }
        }
      }
    },
    searchPayee() {
      getSshPayee({
        customerId: this.form.clclid,
        payeeId: this.form.blcbid,
        role: this.accountInfo.role
      }).then((response) => {
        this.form.payeeMode = 1;
        this.form.blclid = response.data.blclid;
        this.form.blcbid = response.data.blcbid;
        this.form.blnmkj = response.data.blnmkj + response.data.blnkj2 + response.data.blnkj3;
        this.form.blnmkn = response.data.blnmkn + response.data.blnkn2 + response.data.blnkn3;
        this.form.blzipc = response.data.blzipc;
        this.form.blakj1 = response.data.blakj1;
        this.form.blakj2 = response.data.blakj2;
        this.form.recipientName = this.form.blnmkj;
        this.form.blltel = response.data.blltel;
        this.form.bllfax = response.data.bllfax;
        this.form.blcmmt = response.data.blcmmt;
        this.form.blstcd = response.data.blstcd;
        this.form.bltflg = response.data.bltflg;
        this.form.blpyr1 = response.data.blpyr1;
        this.form.blplm1 = response.data.blplm1;
        this.form.blplm7 = response.data.blplm7;
        this.form.blplm8 = response.data.blplm8;
        this.form.blplm9 = response.data.blplm9;
        this.form.blplm10 = response.data.blplm10;
        if (response.data.blplm7 != null && response.data.blplm7 != "") {
          this.form.blplmMode = 1;
        } else {
          this.form.blplmMode = 0;
        }
        if ((this.accountInfo.role <= 3 && this.form.gropid == null ) || this.form.gropid <= 3) {
          //直販の場合
          //クラウドサイン情報　会社名
          this.form.mb3CompanyName = this.form.blnmkj;
        }
      });
    },
    addPayeeFlag() {
      this.form.blcbid = null;
      if (this.form.blstcd === "0") {
        this.form.payeeMode = 0;
        this.form.blplmMode = 0;
        this.form.blnmkj = null;
        this.form.blnmkn = null;
        this.form.blzipc = null;
        this.form.bltflg = null;
        this.form.blakj1 = null;
        this.form.blakj2 = null;
        this.form.blpyr1 = null;
        this.form.pmtFlg = null;
        this.form.recipientName = null;
        this.form.paymentNote = null;
        this.form.blplm1 = 0;
        this.form.blplm7 = null;
        this.form.blplm8 = null;
        this.form.blplm9 = null;
        this.form.blplm10 = null;
      }
    },
    handleChangeClassKbn(code) {
      // if (this.form.serviceList != null &&  this.form.serviceList.length > 0) {
      //   this.$confirm('掲載区分が変更されたので、入力したのサービスは全部削除されます。よろしいですか？', '提示', {
      //       confirmButtonText: '確認',
      //       cancelButtonText: '戻る',
      //       type: 'warning'
      //   }).then(() => {
      //     this.form.tmpClassKbn = code;
      //     this.form.serviceList = [];
      //   }).catch(() => {
      //     this.form.classKbn = this.form.tmpClassKbn;
      //   });
      // } else {
      //   this.form.tmpClassKbn = code;
      // }
      getSubLocalKbnList({
        kbType: "SK009",
        kbCode: code,
        subKbType: "SK013"
      }).then((response) => {
        this.form.jobCategory = null;
        this.form.cboccp = null;
        this.jobList = response.data;
      });
    },
    addServiceTemp() {
      if (this.$refs['serviceForm'] !== undefined) {
        this.$refs['serviceForm'].resetFields();
      }
      this.serviceForm.mode = 0;
      this.serviceForm.addServiceVisible = true;
      this.serviceForm.cdsvtpsr = null;
      this.serviceForm.cdsvtp = null;
      this.serviceForm.cdsvtpNm = null;
      this.serviceForm.cdsvnmsr = null;
      this.serviceForm.cdsvnm = null;
      this.serviceForm.cdsvnmNm = null;
      this.serviceForm.cdplcd = null;
      this.serviceForm.cdplnm = null;
      this.serviceForm.cdpbar = null;
      this.serviceForm.cdpbarNm = null;
      this.serviceForm.cdlti1 = null;
      this.serviceForm.cdlti1Nm = null;
      this.serviceForm.cdlti2 = null;
      this.serviceForm.cdlti2Nm = null;
      this.serviceForm.cdcpn1 = null;
      this.serviceForm.cdcpn1Nm = null;
      this.serviceForm.cdcpn2 = null;
      this.serviceForm.cdcpn2Nm = null;
      this.serviceForm.cdoccp = null;
      this.serviceForm.cdterm = null;
      this.serviceForm.cdstrd = null;
      this.serviceForm.cdendd = null;
      this.serviceForm.cdbcmt = '';
      this.serviceForm.cdslcn = null;
      this.serviceForm.cdlprc = null;
      if (this.accountInfo.role == 4) {
        this.serviceForm.cddcnt = 0;
      } else {
        this.serviceForm.cddcnt = null;
      }
      this.serviceForm.cdstrc = null;
      this.serviceForm.cdsprc = 0;
      this.serviceForm.cdcost = 0;
      this.serviceForm.cdprft = 0;
      this.serviceForm.cdblcn = null;
      this.serviceForm.blblpd = null;
      this.serviceForm.bldppd = null;
      this.serviceForm.profit = null;
      this.serviceForm.profitDiv = null;
      this.serviceForm.cdocmt = '';
      this.cdsvnmsrList = [];
      this.cdsvnmList = [];
      this.cdplnmList = [];
      this.printAreaList = [];

      getLocalKbnList({
        kbType: "SK001"
      }).then((response) => {
        this.cdsvtpsrList = response.data;
      });
      getKbnList({
        kbType: "K007"
      }).then((response) => {
        this.cdsvtpList = response.data;
      });
    },
    handleChangeStpsr(type) { 
      var cdsvtpsrNm = "";
      for (var i = 0; i < this.cdsvtpsrList.length; i++) {
        var selData1 = this.cdsvtpsrList[i];
        if (selData1.kbCode === type) {
          if (selData1.kbName == "アールエイチパック" 
            || selData1.kbName == "アールエイチパック(VU)" 
            || selData1.kbName == "アールエイチナビ") {
              cdsvtpsrNm = "アールエイチ"
          } else {
            cdsvtpsrNm = selData1.kbName;
          }
          break;
        }
      }

      for (var i = 0; i < this.cdsvtpList.length; i++) {
        var selData1 = this.cdsvtpList[i];
        if (selData1.kbName === cdsvtpsrNm) {
          this.serviceForm.cdsvtp = selData1.kbCode;
          break;
        }
      }

      this.serviceForm.cdsvnmsr = null;
      this.serviceForm.cdsvnm = null;
      this.serviceForm.cdplcd = null;
      this.serviceForm.cdpbar = null;
      this.serviceForm.cdpbarNm = null;
      this.serviceForm.cdcpn1 = null;
      this.serviceForm.cdcpn2 = null;
      this.cdsvnmsrList = [];
      this.cdsvnmList = [];
      this.cdplnmList = [];
      this.printAreaList = [];
      getSubLocalKbnList({
        kbType: "SK001",
        kbCode: type,
        subKbType: "SK002"
      }).then((response) => {
        this.cdsvnmsrList = response.data;
      });
      getSubKbnList({
        kbType: "K007",
        kbCode: this.serviceForm.cdsvtp,
        mode: 2
      }).then((response) => {
        this.cdsvnmList = response.data;
      });
    },
    handleChangeSvnmsr(code) {
      this.serviceForm.cdplcd = null;
      this.serviceForm.cdpbar = null;
      this.serviceForm.cdpbarNm = null;
      this.serviceForm.cdcpn1 = null;
      this.serviceForm.cdcpn2 = null;
      this.cdplnmList = [];
      this.printAreaList = [];
      getSubLocalKbnList({
        kbType: "SK002",
        kbCode: code,
        subKbType: "SK003"
      }).then((response) => {
        this.cdplnmList = response.data;
      });

      getCampaignList({
        kbCode1: this.serviceForm.cdsvtpsr,
        kbCode2: code
      }).then((response) => {
        this.cdcpnList = response.data;
      });

      var getCost = function (kbName, targetForm) {
        //获取原率
        getRateByName({
          kbName: kbName
        }).then((response) => {
          var rate = response.data ? response.data.rate : 0;
          //原価
          if (rate && rate !== 0) {
            targetForm.cdcost = (targetForm.cdstrc * rate).toFixed(0);
          } else {
            targetForm.cdcost = 0;
          }
          //利益
          targetForm.cdprft = targetForm.cdsprc - targetForm.cdcost;
        });
      };

      if (this.serviceForm.cdslcn && this.serviceForm.cdslcn != "" && 
          this.serviceForm.cdstrc && this.serviceForm.cdstrc != "" && 
          this.serviceForm.cddcnt && this.serviceForm.cddcnt != "") {
        //売価
        this.serviceForm.cdlprc = (this.serviceForm.cdstrc / this.serviceForm.cdslcn).toFixed(0);
        this.serviceForm.cdsprc = (this.serviceForm.cdstrc - this.serviceForm.cddcnt).toFixed(0);
        if (this.cdsvnmsrList && this.cdsvnmsrList.length > 0 && this.serviceForm.cdsvnmsr && this.serviceForm.cdsvnmsr != "" && 
            this.cdsvnmList && this.cdsvnmList.length > 0 && this.serviceForm.cdsvnm && this.serviceForm.cdsvnm != "" ) {
          for (var i = 0; i < this.cdsvnmsrList.length; i++) {
            var selData = this.cdsvnmsrList[i];
            if (selData.kbCode === this.serviceForm.cdsvnmsr) {
              if (selData.kbName == "マッハバイト" || selData.kbName == "ジョブポスト" ||
                  selData.kbName == "ユメックス" || selData.kbName == "@type" || 
                  selData.kbName == "女の転職＠type" || selData.kbName == "シフトワークス" || 
                  selData.kbName == "meet in" || selData.kbName == "求人ボックス" || 
                  selData.kbName == "スタンバイ" || selData.kbName == "doda" || selData.kbName == "しゅふJOB" || 
                  selData.kbName == "求人ダイレクト" || selData.kbName == "求人飲食店ドットコム") {

                for (var j = 0; j < this.cdsvnmList.length; j++) {
                  var selData1 = this.cdsvnmList[j];
                  if (selData1.kbCode === this.serviceForm.cdsvnm) {
                    getCost(selData1.kbName, this.serviceForm);
                    break;
                  }
                }
              } else {
                getCost(selData.kbName, this.serviceForm);
              }
              break;
            }
          }
        }
      }
      this.calcPayDate(this.cdsvnmsrList, this.serviceForm);
    },
    handleChangePlnm(code) {
      this.serviceForm.cdpbar = null;
      this.serviceForm.cdpbarNm = null;
      this.printAreaList = [];
      let i = this.cdplnmList.findIndex(item=>item.kbCode === code);
      if(i >= 0) {
        getSubLocalKbnList2({
          kbType1: "SK002",
          kbCode1: this.serviceForm.cdsvnmsr,
          kbType2: "SK003",
          kbCode2: code,
          subKbType: "SK005"
        }).then((response) => {
          this.printAreaList = response.data;
        });
      }
    },
    handleChangePlnm2(code, cdsvnmsr) {
      this.serviceForm.cdpbar = null;
      this.serviceForm.cdpbarNm = null;
      this.printAreaList = [];
      var re = /^[0-9]+.?[0-9]*/;//判断字符串是否为数字//判断正整数/[1−9]+[0−9]∗]∗/ 
      if (code != null && code != "" && re.test(code) && cdsvnmsr != null) { 
        getSubLocalKbnList2({
          kbType1: "SK002",
          kbCode1: cdsvnmsr,
          kbType2: "SK003",
          kbCode2: code,
          subKbType: "SK005"
        }).then((response) => {
          this.printAreaList = response.data;
        });
      }
    },
    handleChangePrta(code) {
      this.serviceForm.cdlti1 = null;
      this.serviceForm.cdlti2 = null;
      if (code != null && code != "") {
        getSubLocalKbnList({
          kbType: "SK005",
          kbCode: code,
          subKbType: "SK006"
        }).then((response) => {
          this.listInfo1List = response.data;
        });
      }
    },
    handleChangeInfo(code) {
      if (code != null && code != "") {
        this.serviceForm.cdlti2 = null;
        getSubLocalKbnList3({
          kbType1: "SK005",
          kbCode1: this.serviceForm.cdpbar,
          kbType2: "SK006",
          kbCode2: code,
          subKbType: "SK007"
        }).then((response) => {
          this.listInfo2List = response.data;
        });
      }
    },
    handleCalculatePrice(value) {
      var getCost = function (kbName, targetForm) {
        //获取原率
        getRateByName({
          kbName: kbName
        }).then((response) => {
          var rate = response.data ? response.data.rate : 0;
          //原価
          if (rate && rate !== 0) {
            targetForm.cdcost = (targetForm.cdstrc * rate).toFixed(0);
          } else {
            targetForm.cdcost = 0;
          }
          //利益
          targetForm.cdprft = targetForm.cdsprc - targetForm.cdcost;
        });
      };

      if (!isNullOrUndefinedOrEmpty(this.serviceForm.cdslcn) && 
          !isNullOrUndefinedOrEmpty(this.serviceForm.cdstrc) && 
          !isNullOrUndefinedOrEmpty(this.serviceForm.cddcnt)) {
        //売価
        this.serviceForm.cdlprc = (this.serviceForm.cdstrc / this.serviceForm.cdslcn).toFixed(0);
        this.serviceForm.cdsprc = (this.serviceForm.cdstrc - this.serviceForm.cddcnt).toFixed(0);
        if (this.cdsvnmsrList && this.cdsvnmsrList.length > 0 && this.serviceForm.cdsvnmsr && this.serviceForm.cdsvnmsr != "" && 
            this.cdsvnmList && this.cdsvnmList.length > 0 && this.serviceForm.cdsvnm && this.serviceForm.cdsvnm != "" ) {
          for (var i = 0; i < this.cdsvnmsrList.length; i++) {
            var selData = this.cdsvnmsrList[i];
            if (selData.kbCode === this.serviceForm.cdsvnmsr) {
              if (selData.kbName == "マッハバイト" || selData.kbName == "ジョブポスト" ||
                  selData.kbName == "ユメックス" || selData.kbName == "@type" || 
                  selData.kbName == "女の転職＠type" || selData.kbName == "シフトワークス" || 
                  selData.kbName == "meet in" || selData.kbName == "求人ボックス" || 
                  selData.kbName == "スタンバイ" || selData.kbName == "doda" || selData.kbName == "しゅふJOB" || 
                  selData.kbName == "求人ダイレクト" || selData.kbName == "求人飲食店ドットコム") {

                for (var j = 0; j < this.cdsvnmList.length; j++) {
                  var selData1 = this.cdsvnmList[j];
                  if (selData1.kbCode === this.serviceForm.cdsvnm) {
                    getCost(selData1.kbName, this.serviceForm);
                    break;
                  }
                }
              } else {
                getCost(selData.kbName, this.serviceForm);
              }
              break;
            }
          }
        }
      }
    },
    handleCalEndDate(){
      if (this.serviceForm.cdterm) {
        if (this.accountInfo.role > 3) {
          if (this.serviceForm.cdterm <= 3) {
            this.serviceForm.profitDiv = 1;
          } else {
            this.serviceForm.profitDiv = Math.floor(this.serviceForm.cdterm/4);
          }
        }
        if (this.serviceForm.cdstrd) {
          var startDay = new Date(this.serviceForm.cdstrd);
          if (this.serviceForm.cdplnm && this.serviceForm.cdplnm == "リクラクチケット") {
            startDay.setMonth(startDay.getMonth() + 13);
          } else {
            startDay.setDate(startDay.getDate() - 1 + 7*this.serviceForm.cdterm);
          }
          this.serviceForm.cdendd = getDateTimeStr1(startDay);
        }
      }
    },
    handleSetApplyDate(){
      this.calcPayDate(this.cdsvnmsrList, this.serviceForm);
    },
    saveService(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          //验证オールリスティングの職種必须输入
          var cdsvnmsrNm = ''
          for (var i = 0; i < this.cdsvnmsrList.length; i++) {
            var selData = this.cdsvnmsrList[i];
            if (this.serviceForm.cdsvnmsr === selData.kbCode) {
              cdsvnmsrNm = selData.kbName;
              break
            }
          }
          if (cdsvnmsrNm && cdsvnmsrNm.indexOf("オールリスティング") >= 0 && (this.serviceForm.cdoccp== 0 || this.serviceForm.cdoccp == null)) {
            this.$message({
              message: "サービス名（申）で「オールリスティング」が選択された場合、オールリスティングの職種の入力を必須します。",
              type: "error",
            });
            return false;
          }

          if (this.accountInfo.role <= 3 && (this.form.gropid == null || this.form.gropid <= 3)) {
            for (var i = 0; i < this.cdsvtpsrList.length; i++) {
              var selData = this.cdsvtpsrList[i];
              if (selData.kbCode === this.serviceForm.cdsvtpsr) {
                if (selData.kbName == "Indeed") {
                  if (this.form.gy1MailAddress != "indeed@p-partners.co.jp" 
                  && this.form.gy2MailAddress != "indeed@p-partners.co.jp"
                  && this.form.gy3MailAddress != "indeed@p-partners.co.jp"
                  && this.form.gy4MailAddress != "indeed@p-partners.co.jp") {
                    if (!this.form.gy1MailAddress) {
                      this.form.gy1MailAddress = "indeed@p-partners.co.jp";
                      this.form.gy1Name = "Indeedチーム";
                    } else if (!this.form.gy2MailAddress) {
                      this.form.gy2MailAddress = "indeed@p-partners.co.jp";
                      this.form.gy2Name = "Indeedチーム";
                    } else if (!this.form.gy3MailAddress) {
                      this.form.gy3MailAddress = "indeed@p-partners.co.jp";
                      this.form.gy3Name = "Indeedチーム";
                    } else if (!this.form.gy4MailAddress) {
                      this.form.gy4MailAddress = "indeed@p-partners.co.jp";
                      this.form.gy4Name = "Indeedチーム";
                    }
                  }
                }
                break;
              }
            }
          }
          for (var i = 0; i < this.cdsvtpList.length; i++) {
            var selData = this.cdsvtpList[i];
            if (this.serviceForm.cdsvtp === selData.kbCode) {
              this.serviceForm.cdsvtpNm = selData.kbName;
            }
          }
          for (var i = 0; i < this.cdsvnmList.length; i++) {
            var selData = this.cdsvnmList[i];
            if (this.serviceForm.cdsvnm === selData.kbCode) {
              this.serviceForm.cdsvnmNm = selData.kbName;
            }
          }
          let idx = this.cdplnmList.findIndex(item=>item.kbCode === this.serviceForm.cdplcd);
          if(idx < 0) {
              this.serviceForm.cdplnm = this.serviceForm.cdplcd;
          } else {
            for (var i = 0; i < this.cdplnmList.length; i++) {
              var selData = this.cdplnmList[i];
              if (this.serviceForm.cdplcd === selData.kbCode) {
                this.serviceForm.cdplnm = selData.kbName;
              }
            } 
          }
          for (var i = 0; i < this.printAreaList.length; i++) {
            var selData = this.printAreaList[i];
            if (this.serviceForm.cdpbar === selData.kbCode) {
              this.serviceForm.cdpbarNm = selData.kbName;
            }
          }
          for (var i = 0; i < this.listInfo1List.length; i++) {
            var selData = this.listInfo1List[i];
            if (this.serviceForm.cdlti1 === selData.kbCode) {
              this.serviceForm.cdlti1Nm = selData.kbName;
            }
          }
          for (var i = 0; i < this.listInfo2List.length; i++) {
            var selData = this.listInfo2List[i];
            if (this.serviceForm.cdlti2 === selData.kbCode) {
              this.serviceForm.cdlti2Nm = selData.kbName;
            }
          }
          for (var i = 0; i < this.cdcpnList.length; i++) {
            var selData = this.cdcpnList[i];
            if (this.serviceForm.cdcpn1 === selData.kbCode) {
              this.serviceForm.cdcpn1Nm = selData.kbName.trim();
            }
            if (this.serviceForm.cdcpn2 === selData.kbCode) {
              this.serviceForm.cdcpn2Nm = selData.kbName.trim();
            }
          }

          //新規第一条的场合
          if (this.form.serviceList != null && this.form.serviceList.length == 0) {
            //掲載区分の設定
            var classKbnNm = "";
            if (this.serviceForm.cdcpn1Nm != "派遣・請負料金" && this.serviceForm.cdcpn2Nm != "派遣・請負料金") {
              if (this.serviceForm.cdsvnmNm == "バイトル") {
                classKbnNm = "一般企業・店舗";
              } else if (this.serviceForm.cdsvnmNm == "はたらこねっと") {
                classKbnNm = "一般企業・店舗：はたらこ";
              } else if (this.serviceForm.cdsvnmNm == "バイトルPRO") {
                classKbnNm = "一般企業・店舗：PRO";
              }

            } else {
              if (this.serviceForm.cdsvnmNm == "バイトル") {
                classKbnNm = "ＡＧ（派遣企業）";
              } else if (this.serviceForm.cdsvnmNm == "はたらこねっと") {
                classKbnNm = "ＡＧ（派遣企業）：はたらこ";
              } else if (this.serviceForm.cdsvnmNm == "バイトルPRO") {
                classKbnNm = "ＡＧ（派遣企業）：PRO";
              }
            }
            if (this.serviceForm.cdsvnmNm == "面接コボット") {
              classKbnNm = "ＤＸ商材";
            } else {
              for (var i = 0; i < this.jobList.length; i++) {
                var selData = this.jobList[i];
                if (this.form.jobCategory === selData.kbCode && selData.kbName === "ガールズバー・キャバクラ・スナック") {
                  classKbnNm = "ナイト";
                  break;
                }
              }
            }

            if (classKbnNm != "") {
              for (var i = 0; i < this.classificationOptions.length; i++) {
                var selData = this.classificationOptions[i];
                if (classKbnNm === selData.kbName) {
                  //重新设定掲載区分
                  this.form.classKbn = selData.kbCode
                  getSubLocalKbnList({
                    kbType: "SK009",
                    kbCode: selData.kbCode,
                    subKbType: "SK013"
                  }).then((response) => {
                    this.form.jobCategory = null;
                    this.form.cboccp = null;
                    this.jobList = response.data;
                  });
                  break;
                }
              }
            }
          }
          this.form.serviceList.push(JSON.parse(JSON.stringify(this.serviceForm)));

          var minDate = "";
          var chkRtn = false
          for (var i = 0; i < this.form.serviceList.length; i++) {
            var serData = this.form.serviceList[i];
            if (i === 0 || minDate > serData.cdstrd) {
              minDate = serData.cdstrd;
            }
            //验证揭载日是否重复
            if (chkRtn === true || serData.cdstrd === serData.cdendd) {
              chkRtn = true
              continue
            }
            for (var j = i + 1; j < this.form.serviceList.length; j++) {
              var serData2 = this.form.serviceList[j];
              if (serData.cdstrd === serData2.cdendd || serData2.cdstrd === serData.cdendd) {
                chkRtn = true
                break
              }
            }
          }
          if (chkRtn) {
            this.$confirm('終了日と開始日が重複してますがお間違いないでしょうか？', '提示', {
              confirmButtonText: '問題ない',
              cancelButtonText: '修正',
              type: 'warning'
            }).then(() => {
              this.serviceForm.addServiceVisible = false;
            }).catch(() => {
              this.form.serviceList.splice(this.form.serviceList.length - 1, 1)
            });
          } else {
            this.serviceForm.addServiceVisible = false;
          }
          this.form.cbncdy = this.weekDayList[new Date(minDate).getDay()];
        } else {
          this.$message({
            message: "画面項目に入力ミスがあります。ご確認お願いいたします。",
            type: "error",
          });
          return false;
        }
      });
    },
    updateService(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          //验证オールリスティングの職種必须输入
          var cdsvnmsrNm = ''
          for (var i = 0; i < this.cdsvnmsrList.length; i++) {
            var selData = this.cdsvnmsrList[i];
            if (this.serviceForm.cdsvnmsr === selData.kbCode) {
              cdsvnmsrNm = selData.kbName;
              break
            }
          }
          if (cdsvnmsrNm && cdsvnmsrNm.indexOf("オールリスティング") >= 0 && (this.serviceForm.cdoccp== 0 || this.serviceForm.cdoccp == null)) {
            this.$message({
              message: "サービス名（申）で「オールリスティング」が選択された場合、オールリスティングの職種の入力を必須します。",
              type: "error",
            });
            return false;
          }
          
          if (this.accountInfo.role <= 3 && (this.form.gropid == null || this.form.gropid <= 3)) {
            for (var i = 0; i < this.cdsvtpsrList.length; i++) {
              var selData = this.cdsvtpsrList[i];
              if (selData.kbCode === this.serviceForm.cdsvtpsr) {
                if (selData.kbName == "Indeed") {
                  if (this.form.gy1MailAddress != "indeed@p-partners.co.jp" 
                  && this.form.gy2MailAddress != "indeed@p-partners.co.jp"
                  && this.form.gy3MailAddress != "indeed@p-partners.co.jp"
                  && this.form.gy4MailAddress != "indeed@p-partners.co.jp") {
                    if (!this.form.gy1MailAddress) {
                      this.form.gy1MailAddress = "indeed@p-partners.co.jp";
                      this.form.gy1Name = "Indeedチーム";
                    } else if (!this.form.gy2MailAddress) {
                      this.form.gy2MailAddress = "indeed@p-partners.co.jp";
                      this.form.gy2Name = "Indeedチーム";
                    } else if (!this.form.gy3MailAddress) {
                      this.form.gy3MailAddress = "indeed@p-partners.co.jp";
                      this.form.gy3Name = "Indeedチーム";
                    } else if (!this.form.gy4MailAddress) {
                      this.form.gy4MailAddress = "indeed@p-partners.co.jp";
                      this.form.gy4Name = "Indeedチーム";
                    }
                  }
                }
                break;
              }
            }
          }
          for (var i = 0; i < this.cdsvtpList.length; i++) {
            var selData = this.cdsvtpList[i];
            if (this.serviceForm.cdsvtp === selData.kbCode) {
              this.serviceForm.cdsvtpNm = selData.kbName;
            }
          }
          for (var i = 0; i < this.cdsvnmList.length; i++) {
            var selData = this.cdsvnmList[i];
            if (this.serviceForm.cdsvnm === selData.kbCode) {
              this.serviceForm.cdsvnmNm = selData.kbName;
            }
          }
          let idx = this.cdplnmList.findIndex(item=>item.kbCode === this.serviceForm.cdplcd);
          if(idx < 0) {
              this.serviceForm.cdplnm = this.serviceForm.cdplcd;
          } else {
            for (var i = 0; i < this.cdplnmList.length; i++) {
              var selData = this.cdplnmList[i];
              if (this.serviceForm.cdplcd === selData.kbCode) {
                this.serviceForm.cdplnm = selData.kbName;
              }
            } 
          }
          for (var i = 0; i < this.printAreaList.length; i++) {
            var selData = this.printAreaList[i];
            if (this.serviceForm.cdpbar === selData.kbCode) {
              this.serviceForm.cdpbarNm = selData.kbName;
            }
          }
          this.serviceForm.cdlti1Nm = null;
          this.serviceForm.cdlti2Nm = null;
          this.serviceForm.cdcpn1Nm = null;
          this.serviceForm.cdcpn2Nm = null;
          for (var i = 0; i < this.listInfo1List.length; i++) {
            var selData = this.listInfo1List[i];
            if (this.serviceForm.cdlti1 === selData.kbCode) {
              this.serviceForm.cdlti1Nm = selData.kbName;
            }
          }
          for (var i = 0; i < this.listInfo2List.length; i++) {
            var selData = this.listInfo2List[i];
            if (this.serviceForm.cdlti2 === selData.kbCode) {
              this.serviceForm.cdlti2Nm = selData.kbName;
            }
          }
          for (var i = 0; i < this.cdcpnList.length; i++) {
            var selData = this.cdcpnList[i];
            if (this.serviceForm.cdcpn1 === selData.kbCode) {
              this.serviceForm.cdcpn1Nm = selData.kbName.trim();
            }
            if (this.serviceForm.cdcpn2 === selData.kbCode) {
              this.serviceForm.cdcpn2Nm = selData.kbName.trim();
            }
          }
          this.form.serviceList.splice(this.serviceForm.idx,1,JSON.parse(JSON.stringify(this.serviceForm)));

          var minDate = "";
          var chkRtn = false
          for (var i = 0; i < this.form.serviceList.length; i++) {
            var serData = this.form.serviceList[i];
            if (i === 0 || minDate > serData.cdstrd) {
              minDate = serData.cdstrd;
            }
            //验证揭载日是否重复
            if (chkRtn === true || serData.cdstrd === serData.cdendd) {
              chkRtn = true
              continue
            }
            for (var j = i + 1; j < this.form.serviceList.length; j++) {
              var serData2 = this.form.serviceList[j];
              if (serData.cdstrd === serData2.cdendd || serData2.cdstrd === serData.cdendd) {
                chkRtn = true
                break
              }
            }
          }
          if (chkRtn) {
            this.$confirm('終了日と開始日が重複してますがお間違いないでしょうか？', '提示', {
              confirmButtonText: '問題ない',
              cancelButtonText: '修正',
              type: 'warning'
            }).then(() => {
              this.serviceForm.addServiceVisible = false;
            }).catch(() => {
            });
          } else {
            this.serviceForm.addServiceVisible = false;
          }
          this.form.cbncdy = this.weekDayList[new Date(minDate).getDay()];
        } else {
          this.$message({
            message: "画面項目に入力ミスがあります。ご確認お願いいたします。",
            type: "error",
          });
          return false;
        }
      });
    },
    addRecruitingTemp() {
      if (this.$refs['recruitingForm'] !== undefined) {
        this.$refs['recruitingForm'].resetFields();
      }
      this.recruitingForm.mode = 0;
      this.recruitingForm.addVisible = true;
      this.recruitingForm.copyFlg = 1;
      this.recruitingForm.advtid = "";
      this.recruitingForm.advtkj = "";
      this.recruitingForm.address1 = "";
      this.recruitingForm.address2 = "";
      this.recruitingForm.address3 = "";
      this.recruitingForm.advtel = "";
      this.recruitingForm.adbzty = null;
      this.recruitingForm.adcmmt = "";
      this.recruitingForm.adstcd = 0;
      getKbnList({
        kbType: "K002"
      }).then((response) => {
        this.adbztyList = response.data;
      });
      getAdvtidList({
        customerId: this.form.clclid,
      }).then((response) => {
        this.advtidList = response.data;
      });
    },
    searchRecruiting() {
      getSshRecruiting({
        customerId: this.form.clclid,
        recruitingId: this.recruitingForm.advtid
      }).then((response) => {
        // this.recruitingForm.mode = 1;
        this.recruitingForm.advtkj = response.data.advtkj;
        this.recruitingForm.address1 = response.data.address1;
        this.recruitingForm.address2 = response.data.address2;
        this.recruitingForm.address3 = response.data.address3;
        this.recruitingForm.advtel = response.data.advtel;
        this.recruitingForm.adbzty = response.data.adbzty + "";
        this.recruitingForm.adcmmt = response.data.adcmmt;
        this.recruitingForm.adstcd = response.data.adstcd;
      });
    },
    handleChangeAdvt() {
      if (this.recruitingForm.advtid) {
        for (var i = 0; i < this.advtidList.length; i++) {
          var selData = this.advtidList[i];
          if (this.recruitingForm.advtid === selData.advtid) {
            this.recruitingForm.advtkj = selData.advtkj;
            this.recruitingForm.address1 = selData.address1;
            this.recruitingForm.address2 = selData.address2;
            this.recruitingForm.address3 = selData.address3;
            this.recruitingForm.advtel = selData.advtel;
            this.recruitingForm.adbzty = selData.adbzty + "";
            this.recruitingForm.adcmmt = selData.adcmmt;
            this.recruitingForm.adstcd = selData.adstcd;
            break;
          }
        }
      }
    },
    addRecruitingFlag() {
      if (this.recruitingForm.adstcd === 0) {
        this.recruitingForm.advtid = null;
        this.recruitingForm.mode = 0;
      }
    },
    addRecruiting(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          for (var i = 0; i < this.adbztyList.length; i++) {
            var selData = this.adbztyList[i];
            if (this.recruitingForm.adbzty === selData.kbCode) {
              this.recruitingForm.adbztyNm = selData.kbName;
            }
          }
          this.recruitingForm.adadkj = this.recruitingForm.address1 + this.recruitingForm.address2 + " "  + this.recruitingForm.address3;
          this.form.recruitingList.push(JSON.parse(JSON.stringify(this.recruitingForm)));
          this.recruitingForm.addVisible = false;
        } else {
          this.$message({
            message: "画面項目に入力ミスがあります。ご確認お願いいたします。",
            type: "error",
          });
          return false;
        }
      });
    },
    updateRecruiting(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          for (var i = 0; i < this.adbztyList.length; i++) {
            var selData = this.adbztyList[i];
            if (this.recruitingForm.adbzty === selData.kbCode) {
              this.recruitingForm.adbztyNm = selData.kbName;
            }
          }
          this.recruitingForm.adadkj = this.recruitingForm.address1 + this.recruitingForm.address2 + " "  + this.recruitingForm.address3;
          this.form.recruitingList.splice(this.recruitingForm.idx,1,JSON.parse(JSON.stringify(this.recruitingForm)));
          this.recruitingForm.addVisible = false;
        } else {
          this.$message({
            message: "画面項目に入力ミスがあります。ご確認お願いいたします。",
            type: "error",
          });
          return false;
        }
      });
    },
    handleRecruitingCopy(copyFlg) {
      if (copyFlg == 0) {
        this.recruitingForm.advtkj = this.form.clnmkj;
        this.recruitingForm.advtel = this.form.clttel;
        this.recruitingForm.address1 = this.form.cladkj1;
        this.recruitingForm.address2 = this.form.cladkj2;
        this.recruitingForm.address3 = this.form.cladkj3;
      }
    },
    // handleChangeCopy(copyFlg) {
    //   if (copyFlg == 0) {
    //     this.form.blnmkj = this.form.clnmkj;
    //     this.form.blnmkn = this.form.clnmkn;
    //     this.form.blzipc = this.form.clzipc;
    //     this.form.blakj1 = this.form.cladkj1 + this.form.cladkj2 + this.form.cladkj3;
    //     this.form.recipientName = this.form.clnmkj;
    //     if (this.form.clnmkj != null && this.form.clnmkj != "") {
    //       this.form.cbpkbn = 1;
    //     }
    //   }
    // },
    handleChangeCboccp(code) {
      this.form.jobCategory = code;
    },
    // handleInitPrintMode(nameKj) {
    //   if (this.form.blnmkj === nameKj && this.form.recipientName === nameKj) {
    //     this.form.cbpkbn = 1;
    //   } else {
    //     this.form.cbpkbn = 2;
    //   }
    // },
    handleClear(type) {
      if (type == 2) {
        this.form.sectionId21 = ''
        this.form.sectionId22 = '';
        this.form.cbsic2 = '';
        this.form.cbrte2 = null;
        this.section22List = [];
        this.member2List = [];
      } else if (type == 3) {
        this.form.sectionId31 = ''
        this.form.sectionId32 = '';
        this.form.cbsic3 = '';
        this.form.cbrte3 = null;
        this.section32List = [];
        this.member3List = [];
      } else if (type == 4) {
        this.form.sectionId41 = ''
        this.form.sectionId42 = '';
        this.form.cbsic4 = '';
        this.form.cbrte4 = null;
        this.section42List = [];
        this.member4List = [];
      } else if (type == 5) {
        this.form.sectionId51 = ''
        this.form.sectionId52 = '';
        this.form.cbsic5 = '';
        this.form.cbrte5 = null;
        this.section52List = [];
        this.member5List = [];
      } else if (type == 6) {
        this.form.sectionId61 = ''
        this.form.sectionId62 = '';
        this.form.cbsic6 = '';
        this.form.cbrte6 = null;
        this.section62List = [];
        this.member6List = [];
      } else if (type == 7) {
        this.form.sectionId71 = ''
        this.form.sectionId72 = '';
        this.form.cbsic7 = '';
        this.form.cbrte7 = null;
        this.section72List = [];
        this.member7List = [];
      } else if (type == 8) {
        this.form.sectionId81 = ''
        this.form.sectionId82 = '';
        this.form.cbsic8 = '';
        this.form.cbrte8 = null;
        this.section82List = [];
        this.member8List = [];
      } else if (type == 9) {
        this.form.sectionId91 = ''
        this.form.sectionId92 = '';
        this.form.cbsic9 = '';
        this.form.cbrte9 = null;
        this.section92List = [];
        this.member9List = [];
      } else if (type == 10) {
        this.form.sectionId101 = ''
        this.form.sectionId102 = '';
        this.form.cbsic10 = '';
        this.form.cbrte10 = null;
        this.section102List = [];
        this.member10List = [];
      }
    },
    handleChangeSection11(sectionId) {
      this.form.sectionId12 = '';
      this.form.cbsic1 = '';
      this.section12List = [];
      this.member1List = [];
      getSubKbnList({
        kbType: "K009",
        kbCode: sectionId,
        mode: 5
      }).then((response) => {
        this.section12List = response.data;
      });
    },
    handleChangeSection21(sectionId) {
      this.form.sectionId22 = '';
      this.form.cbsic2 = '';
      this.section22List = [];
      this.member2List = [];
      getSubKbnList({
        kbType: "K009",
        kbCode: sectionId,
        mode: 5
      }).then((response) => {
        this.section22List = response.data;
      });
    },
    handleChangeSection31(sectionId) {
      this.form.sectionId32 = '';
      this.form.cbsic3 = '';
      this.section32List = [];
      this.member3List = [];
      getSubKbnList({
        kbType: "K009",
        kbCode: sectionId,
        mode: 5
      }).then((response) => {
        this.section32List = response.data;
      });
    },
    handleChangeSection41(sectionId) {
      this.form.sectionId42 = '';
      this.form.cbsic4 = '';
      this.section42List = [];
      this.member4List = [];
      getSubKbnList({
        kbType: "K009",
        kbCode: sectionId,
        mode: 5
      }).then((response) => {
        this.section42List = response.data;
      });
    },
    handleChangeSection51(sectionId) {
      this.form.sectionId52 = '';
      this.form.cbsic5 = '';
      this.section52List = [];
      this.member5List = [];
      getSubKbnList({
        kbType: "K009",
        kbCode: sectionId,
        mode: 5
      }).then((response) => {
        this.section52List = response.data;
      });
    },
    handleChangeSection61(sectionId) {
      this.form.sectionId62 = '';
      this.form.cbsic6 = '';
      this.section62List = [];
      this.member6List = [];
      getSubKbnList({
        kbType: "K009",
        kbCode: sectionId,
        mode: 5
      }).then((response) => {
        this.section62List = response.data;
      });
    },
    handleChangeSection71(sectionId) {
      this.form.sectionId72 = '';
      this.form.cbsic7 = '';
      this.section72List = [];
      this.member7List = [];
      getSubKbnList({
        kbType: "K009",
        kbCode: sectionId,
        mode: 5
      }).then((response) => {
        this.section72List = response.data;
      });
    },
    handleChangeSection81(sectionId) {
      this.form.sectionId82 = '';
      this.form.cbsic8 = '';
      this.section82List = [];
      this.member8List = [];
      getSubKbnList({
        kbType: "K009",
        kbCode: sectionId,
        mode: 5
      }).then((response) => {
        this.section82List = response.data;
      });
    },
    handleChangeSection91(sectionId) {
      this.form.sectionId92 = '';
      this.form.cbsic9 = '';
      this.section92List = [];
      this.member9List = [];
      getSubKbnList({
        kbType: "K009",
        kbCode: sectionId,
        mode: 5
      }).then((response) => {
        this.section92List = response.data;
      });
    },
    handleChangeSection101(sectionId) {
      this.form.sectionId102 = '';
      this.form.cbsic10 = '';
      this.section102List = [];
      this.member10List = [];
      getSubKbnList({
        kbType: "K009",
        kbCode: sectionId,
        mode: 5
      }).then((response) => {
        this.section102List = response.data;
      });
    },
    handleChangeSection12(sectionId) {
      this.form.cbsic1 = '';
      this.member1List = [];
      getMemberList({
        kbCode1: this.form.sectionId11,
        kbCode2: sectionId
      }).then((response) => {
        this.member1List = response.data;
      });
    },
    handleChangeSection22(sectionId) {
      this.form.cbsic2 = '';
      this.member2List = [];
      getMemberList({
        kbCode1: this.form.sectionId21,
        kbCode2: sectionId
      }).then((response) => {
        this.member2List = response.data;
      });
    },
    handleChangeSection32(sectionId) {
      this.form.cbsic3 = '';
      this.member3List = [];
      getMemberList({
        kbCode1: this.form.sectionId31,
        kbCode2: sectionId
      }).then((response) => {
        this.member3List = response.data;
      });
    },
    handleChangeSection42(sectionId) {
      this.form.cbsic4 = '';
      this.member4List = [];
      getMemberList({
        kbCode1: this.form.sectionId41,
        kbCode2: sectionId
      }).then((response) => {
        this.member4List = response.data;
      });
    },
    handleChangeSection52(sectionId) {
      this.form.cbsic5 = '';
      this.member5List = [];
      getMemberList({
        kbCode1: this.form.sectionId51,
        kbCode2: sectionId
      }).then((response) => {
        this.member5List = response.data;
      });
    },
    handleChangeSection62(sectionId) {
      this.form.cbsic6 = '';
      this.member6List = [];
      getMemberList({
        kbCode1: this.form.sectionId61,
        kbCode2: sectionId
      }).then((response) => {
        this.member6List = response.data;
      });
    },
    handleChangeSection72(sectionId) {
      this.form.cbsic7 = '';
      this.member7List = [];
      getMemberList({
        kbCode1: this.form.sectionId71,
        kbCode2: sectionId
      }).then((response) => {
        this.member7List = response.data;
      });
    },
    handleChangeSection82(sectionId) {
      this.form.cbsic8 = '';
      this.member8List = [];
      getMemberList({
        kbCode1: this.form.sectionId81,
        kbCode2: sectionId
      }).then((response) => {
        this.member8List = response.data;
      });
    },
    handleChangeSection92(sectionId) {
      this.form.cbsic9 = '';
      this.member9List = [];
      getMemberList({
        kbCode1: this.form.sectionId91,
        kbCode2: sectionId
      }).then((response) => {
        this.member9List = response.data;
      });
    },
    handleChangeSection102(sectionId) {
      this.form.cbsic10 = '';
      this.member10List = [];
      getMemberList({
        kbCode1: this.form.sectionId101,
        kbCode2: sectionId
      }).then((response) => {
        this.member10List = response.data;
      });
    },
    handleUpdateS(idx,entity) {
      if (this.$refs['serviceForm'] !== undefined) {
        this.$refs['serviceForm'].resetFields();
      }
      getLocalKbnList({
        kbType: "SK001"
      }).then((response) => {
        this.cdsvtpsrList = response.data;
        getKbnList({
          kbType: "K007"
        }).then((response) => {
          this.cdsvtpList = response.data;
          this.serviceForm.cdsvtpsr = entity.cdsvtpsr + "";
          this.handleChangeStpsr(entity.cdsvtpsr + "");
          this.handleChangeSvnmsr(entity.cdsvnmsr + "");
          if (entity.cdplcd !== entity.cdplnm) {
            this.handleChangePlnm2(entity.cdplcd, entity.cdsvnmsr);
          } else {
            this.handleChangePlnm2(null, null);
          }
          if (entity.cdpbar != 0 && entity.cdpbar != null) {
            this.handleChangePrta(entity.cdpbar + "");
            this.serviceForm.cdpbar = entity.cdpbar + "";
          }
          this.serviceForm.cdsvnmsr = entity.cdsvnmsr + "";
          this.serviceForm.idx = idx;
          this.serviceForm.mode = 1;
          this.serviceForm.addServiceVisible = true;
          this.serviceForm.cdsvtp = entity.cdsvtp + "";
          this.serviceForm.cdsvnm = entity.cdsvnm + "";
          this.serviceForm.cdplcd = entity.cdplcd;
          
          if (entity.cdlti1 != 0 && entity.cdlti1 != null) {
            this.handleChangeInfo(entity.cdlti1 + "");
            this.serviceForm.cdlti1 = entity.cdlti1 + "";
          } else {
            this.serviceForm.cdlti1 = null;
          }
          if (entity.cdlti2 != 0 && entity.cdlti2 != null) {
            this.serviceForm.cdlti2 = entity.cdlti2 + "";
          } else {
            this.listInfo2List = [];
            this.serviceForm.cdlti2 = null;
          }

          if (entity.cdcpn1 != 0 && entity.cdcpn1 != null) {
            this.serviceForm.cdcpn1 = entity.cdcpn1 + "";
          }
          if (entity.cdcpn2 != 0 && entity.cdcpn2 != null) {
            this.serviceForm.cdcpn2 = entity.cdcpn2 + "";
          }
          if (entity.cdoccp != 0 && entity.cdoccp != null) {
            this.serviceForm.cdoccp = entity.cdoccp + "";
          }
          this.serviceForm.cdterm = entity.cdterm;
          this.serviceForm.cdstrd = entity.cdstrd;
          this.serviceForm.cdendd = entity.cdendd;
          this.serviceForm.cdbcmt = entity.cdbcmt;
          this.serviceForm.cdslcn = entity.cdslcn;
          this.serviceForm.cdlprc = entity.cdlprc;
          this.serviceForm.cddcnt = entity.cddcnt;
          this.serviceForm.cdstrc = entity.cdstrc;
          this.serviceForm.cdsprc = entity.cdsprc;
          this.serviceForm.cdcost = entity.cdcost;
          this.serviceForm.cdprft = entity.cdprft;
          this.serviceForm.cdblcn = entity.cdblcn;
          this.serviceForm.blblpd = entity.blblpd;
          this.serviceForm.bldppd = entity.bldppd;
          this.serviceForm.profit = entity.profit;
          this.serviceForm.profitDiv = entity.profitDiv;
          this.serviceForm.cdocmt = entity.cdocmt;
        });
      });
    },
    handleDeleteS(idx) {
      this.$confirm('サービス情報を削除します。よろしいですか？', '提示', {
          confirmButtonText: '確認',
          cancelButtonText: '戻る',
          type: 'warning'
      }).then(() => {
        this.form.serviceList.splice(idx, 1);
      });
    },
    handleUpdateR(idx,entity) {
      if (this.$refs['recruitingForm'] !== undefined) {
        this.$refs['recruitingForm'].resetFields();
      }
      this.recruitingForm.idx = idx;
      this.recruitingForm.mode = 1;
      this.recruitingForm.addVisible = true;
      this.recruitingForm.copyFlg = entity.copyFlg;
      this.recruitingForm.advtid = entity.advtid;
      this.recruitingForm.advtkj = entity.advtkj;
      this.recruitingForm.address1 = entity.address1;
      this.recruitingForm.address2 = entity.address2;
      this.recruitingForm.address3 = entity.address3;
      this.recruitingForm.advtel = entity.advtel;
      this.recruitingForm.adbzty = entity.adbzty + "";
      this.recruitingForm.adcmmt = entity.adcmmt;
      this.recruitingForm.adstcd = entity.adstcd + 0;
      getKbnList({
        kbType: "K002"
      }).then((response) => {
        this.adbztyList = response.data;
      });
      getAdvtidList({
        customerId: this.form.clclid,
      }).then((response) => {
        this.advtidList = response.data;
      });
    },
    handleDeleteR(idx) {
      this.$confirm('募集先情報を削除します。よろしいですか？', '提示', {
          confirmButtonText: '確認',
          cancelButtonText: '戻る',
          type: 'warning'
      }).then(() => {
        this.form.recruitingList.splice(idx, 1);
      });
    },
    saveForm(formName) {
      this.$confirm('案件情報を一時保存します。よろしいですか？', '提示', {
          confirmButtonText: '確認',
          cancelButtonText: '戻る',
          type: 'warning'
      }).then(() => {
        var data = {
          employId: this.accountInfo.id,
          groupId: this.accountInfo.groupId,
          customerVo: {
            gropid: this.form.gropid,
            clclid: this.form.clclid,
            cllcid: this.form.cllcid,
            clzipc: this.form.clzipc,
            clmnkj: this.form.clmnkj,
            clmnkn: this.form.clmnkn,
            clmnid: this.form.clmnid,
            clnmkj: this.form.clnmkj,
            clnmkn: this.form.clnmkn,
            cladkj: this.form.cladkj1 + this.form.cladkj2 + " " + this.form.cladkj3,
            cladkn: this.form.cladkn,
            clbdnm: this.form.clbdnm,
            clmnpw: this.form.clmnpw,
            clmnps: this.form.clmnps,
            clmskj: this.form.clmskj,
            clmskn: this.form.clmskn,
            clcomp: this.form.clcomp,
            clickj: this.form.clickj,
            clickn: this.form.clickn,
            clcmmt: this.form.clcmmt,
            clttel: this.form.clttel,
            cltfax: this.form.cltfax,
            clicps: this.form.clicps,
            clplm1: this.form.clplm1,
            clplm2: this.form.clplm2,
            clplm3: this.form.clplm3,
            clplm4: this.form.clplm4,
            clstcd: this.form.clstcd,
            prcsst: this.form.prcsst,
          },
          payeeVo: {
            blclid: this.form.blclid,
            blcbid: this.form.blcbid,
            blnmkj: this.form.blnmkj,
            blnmkn: this.form.blnmkn,
            blzipc: this.form.blzipc,
            blakj1: this.form.blakj1,
            blakj2: this.form.blakj2,
            blltel: this.form.blltel,
            bllfax: this.form.bllfax,
            blcmmt: this.form.blcmmt,
            blstcd: this.form.blstcd,
            bltflg: this.form.bltflg,
            blpyr1: this.form.blpyr1,
            blplm1: this.form.blplm1,
            blplm7: this.form.blplm7,
            blplm8: this.form.blplm8,
            blplm9: this.form.blplm9,
            blplm10: this.form.blplm10,
          },
          contractVo: {
            cbotfl: this.form.cbotfl,
            cbapdt: this.form.cbapdt,
            cbcflg: this.form.cbcflg,
            cbpkbn: this.form.cbpkbn,
            cbncdy: this.form.cbncdy,
            cboccp: this.form.cboccp,
            cbsic1: this.form.cbsic1,
            cbrte1: this.form.cbrte1,
            cbsic2: this.form.cbsic2,
            cbrte2: this.form.cbrte2,
            cbsic3: this.form.cbsic3,
            cbrte3: this.form.cbrte3,
            cbsic4: this.form.cbsic4,
            cbrte4: this.form.cbrte4,
            cbsic5: this.form.cbsic5,
            cbrte5: this.form.cbrte5,
            cbsic6: this.form.cbsic6,
            cbrte6: this.form.cbrte6,
            cbsic7: this.form.cbsic7,
            cbrte7: this.form.cbrte7,
            cbsic8: this.form.cbsic8,
            cbrte8: this.form.cbrte8,
            cbsic9: this.form.cbsic9,
            cbrte9: this.form.cbrte9,
            cbsic10: this.form.cbsic10,
            cbrte10: this.form.cbrte10,
            cbgaic: this.form.cbgaic
          },
          caseDataVo: {
            corporateName: this.form.clnmkj,
            representative: this.form.clickj,
            pmtFlg: this.form.pmtFlg,
            recipientName: this.form.recipientName,
            visitDate: this.form.visitDate,
            visitTime: this.form.visitTime,
            visitChk: this.form.visitChk,
            inheritContent: this.form.inheritContent,
            paymentNote: this.form.paymentNote,
            invoiceIssue: this.form.invoiceIssue,
            pdfDate: this.form.pdfDate,
            sendDate: this.form.sendDate,
            mbPhoneNum: this.form.mbPhoneNum,
            mbMailAddress: this.form.mbMailAddress,
            interview: this.form.interview,
            expectedNum: this.form.expectedNum,
            bannerSearch1: this.form.bannerSearch1,
            bannerSearch2: this.form.bannerSearch2,
            remark: this.form.remark,
            saasCmmt: this.form.saasCmmt,
            contractorNm: this.form.clnmkj,
            mb1MailAddress: this.form.mb1MailAddress,
            mb1Name: this.form.mb1Name,
            mb1CompanyName: this.form.mb1CompanyName,
            mb2MailAddress: this.form.mb2MailAddress,
            mb2Name: this.form.mb2Name,
            mb2CompanyName: this.form.mb2CompanyName,
            mb3MailAddress: this.form.mb3MailAddress,
            mb3Name: this.form.mb3Name,
            mb3CompanyName: this.form.mb3CompanyName,
            mb4MailAddress: this.form.mb4MailAddress,
            mb4Name: this.form.mb4Name,
            mb4CompanyName: this.form.mb4CompanyName,
            gy1MailAddress: this.form.gy1MailAddress,
            gy1Name: this.form.gy1Name,
            gy2MailAddress: this.form.gy2MailAddress,
            gy2Name: this.form.gy2Name,
            gy3MailAddress: this.form.gy3MailAddress,
            gy3Name: this.form.gy3Name,
            gy4MailAddress: this.form.gy4MailAddress,
            gy4Name: this.form.gy4Name,
            appFlow: this.form.appFlow,
            classKbn: this.form.classKbn,
            jobCategory: this.form.jobCategory,
            scndAgencyChk: this.form.scndAgencyChk,
            scndAgency: this.form.scndAgency,
            scndMbCd: this.form.scndMbCd,
          },
          serviceList: this.form.serviceList,
          recruitingList: this.form.recruitingList
        };

        if (this.$route.query.id && this.$route.query.id != "" && (this.$route.query.status == 1 || this.$route.query.status == 3)) {
          data.customerVo.mcltid = this.$route.query.id;
          data.customerVo.clcrpg = this.form.clcrpg;
          data.payeeVo.mbllid = this.form.mbllid;
          data.contractVo.tcrbid = this.form.tcrbid;
          data.caseDataVo.id = this.form.casdid;
          saveCustomer(data).then(response => {
            this.$message({
              message: response.message,
              type: "success"
            });
            this.$router.push(ROUTER_PATH.APPLY_CENTER);
          });
        } else {
          saveCustomer(data).then(response => {
            this.$message({
              message: response.message,
              type: "success"
            });
            this.$router.push(ROUTER_PATH.APPLY_CENTER);
          });
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.form.serviceList == null || this.form.serviceList.length == 0) {
            this.$message({
              message: "サービスを入力してください。",
              type: "error",
            });
            return;
          } else {
            var minDate = null;
            var chkMinDate = null;
            for (var i = 0; i < this.form.serviceList.length; i++) {
              //获取最小掲載開始日
              var serData = this.form.serviceList[i];
              var curDate = new Date(serData.cdstrd);
              if (minDate == null || minDate > curDate) {
                minDate = curDate;
                if ((serData.cdsvnmsr + "") !== "98") {
                  chkMinDate = curDate;
                }
              }
            }

            var cbapdtDate = new Date(this.form.cbapdt)
            //申込日よりも、サービス＞開始日が過去になってた場合
            if (chkMinDate != null && cbapdtDate > chkMinDate) {
              this.$message({
                message: "申込日より過去の掲載開始日が設定できません。",
                type: "error",
              });
              return;
            }

            //契約内容.訪問日時的输入内容验证
            if (this.form.visitDate) {
              var visitDate = new Date(this.form.visitDate)
              //②訪問日時が、掲載開始日よりも未来の場合
              if (visitDate >= minDate) {
                this.$message({
                  message: "訪問日時に未来日は設定できません。",
                  type: "error",
                });
                return;
              }

              //①訪問日時が、掲載開始日から数えて3か月以上前の場合
              minDate.setMonth(minDate.getMonth() - 3);
              if (visitDate <= minDate) {
                this.$message({
                  message: "現地訪問確認は3ヵ月以内に実施ください。",
                  type: "error",
                });
                return;
              }
            }
          }

          //验证利益分配率
          var totalRate = (this.form.cbrte1 ? Number(this.form.cbrte1) : 0) + (this.form.cbrte2 ? Number(this.form.cbrte2) : 0) 
            + (this.form.cbrte3 ? Number(this.form.cbrte3) : 0) + (this.form.cbrte4 ? Number(this.form.cbrte4) : 0)
            + (this.form.cbrte5 ? Number(this.form.cbrte5) : 0) + (this.form.cbrte6 ? Number(this.form.cbrte6) : 0) 
            + (this.form.cbrte7 ? Number(this.form.cbrte7) : 0) + (this.form.cbrte8 ? Number(this.form.cbrte8) : 0) 
            + (this.form.cbrte9 ? Number(this.form.cbrte9) : 0) + (this.form.cbrte10 ? Number(this.form.cbrte10) : 0);
          if (totalRate > 100) {
            this.$message({
              message: "利益分配率の合計値は100以下で入力してください。",
              type: "error",
            });
            return;
          }

          //ポップアップでお仕事NOを入力するの判断
          var ids = ""
          var planIds = ""
          for (var i = 0; i < this.form.serviceList.length; i++) {
            //获取サービス名（申）とプラン名信息
            var serData = this.form.serviceList[i];
            ids += serData.cdsvnmsr + ','
            planIds += serData.cdplcd + ','
          }

          chkNameSr({
            ids: ids,
            planIds: planIds,
          }).then(response => {
            if (response.data === 0) {
              //无需输入仕事NO的场合
              this.$confirm('案件情報を確認依頼します。よろしいですか？', '提示', {
                  confirmButtonText: '確認',
                  cancelButtonText: '戻る',
                  type: 'warning'
              }).then(() => {
                var data = {
                  employId: this.accountInfo.id,
                  groupId: this.accountInfo.groupId,
                  customerVo: {
                    gropid: this.form.gropid,
                    clclid: this.form.clclid,
                    cllcid: this.form.cllcid,
                    clzipc: this.form.clzipc,
                    clmnkj: this.form.clmnkj,
                    clmnkn: this.form.clmnkn,
                    clmnid: this.form.clmnid,
                    clnmkj: this.form.clnmkj,
                    clnmkn: this.form.clnmkn,
                    cladkj: this.form.cladkj1 + this.form.cladkj2 + " " + this.form.cladkj3,
                    cladkn: this.form.cladkn,
                    clbdnm: this.form.clbdnm,
                    clmnpw: this.form.clmnpw,
                    clmnps: this.form.clmnps,
                    clmskj: this.form.clmskj,
                    clmskn: this.form.clmskn,
                    clcomp: this.form.clcomp,
                    clickj: this.form.clickj,
                    clickn: this.form.clickn,
                    clcmmt: this.form.clcmmt,
                    clttel: this.form.clttel,
                    cltfax: this.form.cltfax,
                    clicps: this.form.clicps,
                    clplm1: this.form.clplm1,
                    clplm2: this.form.clplm2,
                    clplm3: this.form.clplm3,
                    clplm4: this.form.clplm4,
                    clstcd: this.form.clstcd,
                    prcsst: this.form.prcsst,
                    bizno: ""
                  },
                  payeeVo: {
                    blclid: this.form.blclid,
                    blcbid: this.form.blcbid,
                    blnmkj: this.form.blnmkj,
                    blnmkn: this.form.blnmkn,
                    blzipc: this.form.blzipc,
                    blakj1: this.form.blakj1,
                    blakj2: this.form.blakj2,
                    blltel: this.form.blltel,
                    bllfax: this.form.bllfax,
                    blcmmt: this.form.blcmmt,
                    blstcd: this.form.blstcd,
                    bltflg: this.form.bltflg,
                    blpyr1: this.form.blpyr1,
                    blplm1: this.form.blplm1,
                    blplm7: this.form.blplm7,
                    blplm8: this.form.blplm8,
                    blplm9: this.form.blplm9,
                    blplm10: this.form.blplm10,
                  },
                  contractVo: {
                    cbotfl: this.form.cbotfl,
                    cbapdt: this.form.cbapdt,
                    cbcflg: this.form.cbcflg,
                    cbpkbn: this.form.cbpkbn,
                    cbncdy: this.form.cbncdy,
                    cboccp: this.form.cboccp,
                    cbsic1: this.form.cbsic1,
                    cbrte1: this.form.cbrte1 ? this.form.cbrte1 : 0,
                    cbsic2: this.form.cbsic2,
                    cbrte2: this.form.cbrte2 ? this.form.cbrte2 : 0,
                    cbsic3: this.form.cbsic3,
                    cbrte3: this.form.cbrte3 ? this.form.cbrte3 : 0,
                    cbsic4: this.form.cbsic4,
                    cbrte4: this.form.cbrte4 ? this.form.cbrte4 : 0,
                    cbsic5: this.form.cbsic5,
                    cbrte5: this.form.cbrte5 ? this.form.cbrte5 : 0,
                    cbsic6: this.form.cbsic6,
                    cbrte6: this.form.cbrte6 ? this.form.cbrte6 : 0,
                    cbsic7: this.form.cbsic7,
                    cbrte7: this.form.cbrte7 ? this.form.cbrte7 : 0,
                    cbsic8: this.form.cbsic8,
                    cbrte8: this.form.cbrte8 ? this.form.cbrte8 : 0,
                    cbsic9: this.form.cbsic9,
                    cbrte9: this.form.cbrte9 ? this.form.cbrte9 : 0,
                    cbsic10: this.form.cbsic10,
                    cbrte10: this.form.cbrte10 ? this.form.cbrte10 : 0,
                    cbgaic: this.form.cbgaic
                  },
                  caseDataVo: {
                    corporateName: this.form.clnmkj,
                    representative: this.form.clickj,
                    pmtFlg: this.form.pmtFlg,
                    recipientName: this.form.recipientName,
                    visitDate: this.form.visitDate,
                    visitTime: this.form.visitTime,
                    visitChk: this.form.visitChk,
                    inheritContent: this.form.inheritContent,
                    paymentNote: this.form.paymentNote,
                    invoiceIssue: this.form.invoiceIssue,
                    pdfDate: this.form.pdfDate,
                    sendDate: this.form.sendDate,
                    mbPhoneNum: this.form.mbPhoneNum,
                    mbMailAddress: this.form.mbMailAddress,
                    interview: this.form.interview,
                    expectedNum: this.form.expectedNum,
                    bannerSearch1: this.form.bannerSearch1,
                    bannerSearch2: this.form.bannerSearch2,
                    remark: this.form.remark,
                    saasCmmt: this.form.saasCmmt,
                    contractorNm: this.form.clnmkj,
                    mb1MailAddress: this.form.mb1MailAddress,
                    mb1Name: this.form.mb1Name,
                    mb1CompanyName: this.form.mb1CompanyName,
                    mb2MailAddress: this.form.mb2MailAddress,
                    mb2Name: this.form.mb2Name,
                    mb2CompanyName: this.form.mb2CompanyName,
                    mb3MailAddress: this.form.mb3MailAddress,
                    mb3Name: this.form.mb3Name,
                    mb3CompanyName: this.form.mb3CompanyName,
                    mb4MailAddress: this.form.mb4MailAddress,
                    mb4Name: this.form.mb4Name,
                    mb4CompanyName: this.form.mb4CompanyName,
                    gy1MailAddress: this.form.gy1MailAddress,
                    gy1Name: this.form.gy1Name,
                    gy2MailAddress: this.form.gy2MailAddress,
                    gy2Name: this.form.gy2Name,
                    gy3MailAddress: this.form.gy3MailAddress,
                    gy3Name: this.form.gy3Name,
                    gy4MailAddress: this.form.gy4MailAddress,
                    gy4Name: this.form.gy4Name,
                    appFlow: this.form.appFlow,
                    classKbn: this.form.classKbn,
                    jobCategory: this.form.jobCategory,
                    scndAgencyChk: this.form.scndAgencyChk,
                    scndAgency: this.form.scndAgency,
                    scndMbCd: this.form.scndMbCd,
                  },
                  serviceList: this.form.serviceList,
                  recruitingList: this.form.recruitingList
                };

                if (this.$route.query.id && this.$route.query.id != "" && (this.$route.query.status == 1 
                    || this.$route.query.status == 3 || this.$route.query.status == 5)) {
                  data.customerVo.mcltid = this.$route.query.id;
                  data.customerVo.clcrpg = this.form.clcrpg;
                  data.payeeVo.mbllid = this.form.mbllid;
                  data.contractVo.tcrbid = this.form.tcrbid;
                  data.caseDataVo.id = this.form.casdid;
                  if (this.$route.query.status == 5) {
                    data.customerVo.cldlcd = 0;
                    data.payeeVo.bldlcd = 0;
                    data.contractVo.cbdlcd = 0;
                    data.caseDataVo.delFlg = 0;
                    if (data.serviceList) {
                      data.serviceList.forEach(item => {
                          item.cddlcd = 0;
                      });
                    }
                    if (data.recruitingList) {
                      data.recruitingList.forEach(item => {
                          item.addlcd = 0;
                      });
                    }
                  }
                  updateCustomer(data).then(response => {
                    this.$message({
                      message: response.message,
                      type: "success"
                    });
                    this.okForm.id = response.data;
                    this.okForm.isVisible = true;
                    //this.$router.push(ROUTER_PATH.APPLY_CENTER);
                  });
                } else {
                  addCustomer(data).then(response => {
                    this.$message({
                      message: response.message,
                      type: "success"
                    });
                    this.okForm.id = response.data;
                    this.okForm.isVisible = true;
                    //this.$router.push(ROUTER_PATH.APPLY_CENTER);
                  });
                }
              });
            } else {
              //需要输入仕事NO的场合
              if (this.$refs['businessForm'] !== undefined) {
                this.$refs['businessForm'].resetFields();
              }
              this.businessForm.isVisible = true;
            }
          });
        } else {
          this.$message({
            message: "画面項目に入力ミスがあります。ご確認お願いいたします。",
            type: "error",
          });
          return false;
        }
      });
    },
    confirmBusiness(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$confirm('案件情報を確認依頼します。よろしいですか？', '提示', {
              confirmButtonText: '確認',
              cancelButtonText: '戻る',
              type: 'warning'
          }).then(() => {
            var data = {
              employId: this.accountInfo.id,
              groupId: this.accountInfo.groupId,
              customerVo: {
                gropid: this.form.gropid,
                clclid: this.form.clclid,
                cllcid: this.form.cllcid,
                clzipc: this.form.clzipc,
                clmnkj: this.form.clmnkj,
                clmnkn: this.form.clmnkn,
                clmnid: this.form.clmnid,
                clnmkj: this.form.clnmkj,
                clnmkn: this.form.clnmkn,
                cladkj: this.form.cladkj1 + this.form.cladkj2 + " " + this.form.cladkj3,
                cladkn: this.form.cladkn,
                clbdnm: this.form.clbdnm,
                clmnpw: this.form.clmnpw,
                clmnps: this.form.clmnps,
                clmskj: this.form.clmskj,
                clmskn: this.form.clmskn,
                clcomp: this.form.clcomp,
                clickj: this.form.clickj,
                clickn: this.form.clickn,
                clcmmt: this.form.clcmmt,
                clttel: this.form.clttel,
                cltfax: this.form.cltfax,
                clicps: this.form.clicps,
                clplm1: this.form.clplm1,
                clplm2: this.form.clplm2,
                clplm3: this.form.clplm3,
                clplm4: this.form.clplm4,
                clstcd: this.form.clstcd,
                prcsst: this.form.prcsst,
                bizno: this.businessForm.bizno
              },
              payeeVo: {
                blclid: this.form.blclid,
                blcbid: this.form.blcbid,
                blnmkj: this.form.blnmkj,
                blnmkn: this.form.blnmkn,
                blzipc: this.form.blzipc,
                blakj1: this.form.blakj1,
                blakj2: this.form.blakj2,
                blltel: this.form.blltel,
                bllfax: this.form.bllfax,
                blcmmt: this.form.blcmmt,
                blstcd: this.form.blstcd,
                bltflg: this.form.bltflg,
                blpyr1: this.form.blpyr1,
                blplm1: this.form.blplm1,
                blplm7: this.form.blplm7,
                blplm8: this.form.blplm8,
                blplm9: this.form.blplm9,
                blplm10: this.form.blplm10,
              },
              contractVo: {
                cbotfl: this.form.cbotfl,
                cbapdt: this.form.cbapdt,
                cbcflg: this.form.cbcflg,
                cbpkbn: this.form.cbpkbn,
                cbncdy: this.form.cbncdy,
                cboccp: this.form.cboccp,
                cbsic1: this.form.cbsic1,
                cbrte1: this.form.cbrte1 ? this.form.cbrte1 : 0,
                cbsic2: this.form.cbsic2,
                cbrte2: this.form.cbrte2 ? this.form.cbrte2 : 0,
                cbsic3: this.form.cbsic3,
                cbrte3: this.form.cbrte3 ? this.form.cbrte3 : 0,
                cbsic4: this.form.cbsic4,
                cbrte4: this.form.cbrte4 ? this.form.cbrte4 : 0,
                cbsic5: this.form.cbsic5,
                cbrte5: this.form.cbrte5 ? this.form.cbrte5 : 0,
                cbsic6: this.form.cbsic6,
                cbrte6: this.form.cbrte6 ? this.form.cbrte6 : 0,
                cbsic7: this.form.cbsic7,
                cbrte7: this.form.cbrte7 ? this.form.cbrte7 : 0,
                cbsic8: this.form.cbsic8,
                cbrte8: this.form.cbrte8 ? this.form.cbrte8 : 0,
                cbsic9: this.form.cbsic9,
                cbrte9: this.form.cbrte9 ? this.form.cbrte9 : 0,
                cbsic10: this.form.cbsic10,
                cbrte10: this.form.cbrte10 ? this.form.cbrte10 : 0,
                cbgaic: this.form.cbgaic
              },
              caseDataVo: {
                corporateName: this.form.clnmkj,
                representative: this.form.clickj,
                pmtFlg: this.form.pmtFlg,
                recipientName: this.form.recipientName,
                visitDate: this.form.visitDate,
                visitTime: this.form.visitTime,
                visitChk: this.form.visitChk,
                inheritContent: this.form.inheritContent,
                paymentNote: this.form.paymentNote,
                invoiceIssue: this.form.invoiceIssue,
                pdfDate: this.form.pdfDate,
                sendDate: this.form.sendDate,
                mbPhoneNum: this.form.mbPhoneNum,
                mbMailAddress: this.form.mbMailAddress,
                interview: this.form.interview,
                expectedNum: this.form.expectedNum,
                bannerSearch1: this.form.bannerSearch1,
                bannerSearch2: this.form.bannerSearch2,
                remark: this.form.remark,
                saasCmmt: this.form.saasCmmt,
                contractorNm: this.form.clnmkj,
                mb1MailAddress: this.form.mb1MailAddress,
                mb1Name: this.form.mb1Name,
                mb1CompanyName: this.form.mb1CompanyName,
                mb2MailAddress: this.form.mb2MailAddress,
                mb2Name: this.form.mb2Name,
                mb2CompanyName: this.form.mb2CompanyName,
                mb3MailAddress: this.form.mb3MailAddress,
                mb3Name: this.form.mb3Name,
                mb3CompanyName: this.form.mb3CompanyName,
                mb4MailAddress: this.form.mb4MailAddress,
                mb4Name: this.form.mb4Name,
                mb4CompanyName: this.form.mb4CompanyName,
                gy1MailAddress: this.form.gy1MailAddress,
                gy1Name: this.form.gy1Name,
                gy2MailAddress: this.form.gy2MailAddress,
                gy2Name: this.form.gy2Name,
                gy3MailAddress: this.form.gy3MailAddress,
                gy3Name: this.form.gy3Name,
                gy4MailAddress: this.form.gy4MailAddress,
                gy4Name: this.form.gy4Name,
                appFlow: this.form.appFlow,
                classKbn: this.form.classKbn,
                jobCategory: this.form.jobCategory,
                scndAgencyChk: this.form.scndAgencyChk,
                scndAgency: this.form.scndAgency,
                scndMbCd: this.form.scndMbCd,
              },
              serviceList: this.form.serviceList,
              recruitingList: this.form.recruitingList
            };

            if (this.$route.query.id && this.$route.query.id != "" && (this.$route.query.status == 1 
                || this.$route.query.status == 3 || this.$route.query.status == 5)) {
              data.customerVo.mcltid = this.$route.query.id;
              data.customerVo.clcrpg = this.form.clcrpg;
              data.payeeVo.mbllid = this.form.mbllid;
              data.contractVo.tcrbid = this.form.tcrbid;
              data.caseDataVo.id = this.form.casdid;
              if (this.$route.query.status == 5) {
                data.customerVo.cldlcd = 0;
                data.payeeVo.bldlcd = 0;
                data.contractVo.cbdlcd = 0;
                data.caseDataVo.delFlg = 0;
                if (data.serviceList) {
                  data.serviceList.forEach(item => {
                      item.cddlcd = 0;
                  });
                }
                if (data.recruitingList) {
                  data.recruitingList.forEach(item => {
                      item.addlcd = 0;
                  });
                }
              }
              updateCustomer(data).then(response => {
                this.$message({
                  message: response.message,
                  type: "success"
                });
                this.okForm.id = response.data;
                this.okForm.isVisible = true;
                //this.$router.push(ROUTER_PATH.APPLY_CENTER);
              });
            } else {
              addCustomer(data).then(response => {
                this.$message({
                  message: response.message,
                  type: "success"
                });
                this.okForm.id = response.data;
                this.okForm.isVisible = true;
                //this.$router.push(ROUTER_PATH.APPLY_CENTER);
              });
            }
          });
        }
      });
    },
    returnList() {
      this.okForm.isVisible = false;
      this.$router.push(ROUTER_PATH.APPLY_CENTER);
    },
    doPreview() {
      let url = process.env.VUE_APP_BASE_API + "/customer/downloadPdf?id=" + this.okForm.id + "&groupId=" + this.accountInfo.groupId;
      window.open(url, '_blank'); // 新开窗口下载
    },
    confirmForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.form.serviceList == null || this.form.serviceList.length == 0) {
            this.$message({
              message: "サービスを入力してください。",
              type: "error",
            });
            return;
          } else {
            var minDate = null;
            var chkMinDate = null;
            for (var i = 0; i < this.form.serviceList.length; i++) {
              //获取最小掲載開始日
              var serData = this.form.serviceList[i];
              var curDate = new Date(serData.cdstrd);
              if (minDate == null || minDate > curDate) {
                minDate = curDate;
                if ((serData.cdsvnmsr + "") !== "98") {
                  chkMinDate = curDate;
                }
              }
            }

            var cbapdtDate = new Date(this.form.cbapdt)
            //申込日よりも、サービス＞開始日が過去になってた場合
            if (chkMinDate != null && cbapdtDate > chkMinDate) {
              this.$message({
                message: "申込日より過去の掲載開始日が設定できません。",
                type: "error",
              });
              return;
            }

            //契約内容.訪問日時的输入内容验证
            if (this.form.visitDate) {
              var visitDate = new Date(this.form.visitDate)
              //②訪問日時が、掲載開始日よりも未来の場合
              if (visitDate >= minDate) {
                this.$message({
                  message: "訪問日時に未来日は設定できません。",
                  type: "error",
                });
                return;
              }

              //①訪問日時が、掲載開始日から数えて3か月以上前の場合
              minDate.setMonth(minDate.getMonth() - 3);
              if (visitDate <= minDate) {
                this.$message({
                  message: "現地訪問確認は3ヵ月以内に実施ください。",
                  type: "error",
                });
                return;
              }
            }
          }

          //验证利益分配率
          var totalRate = (this.form.cbrte1 ? Number(this.form.cbrte1) : 0) + (this.form.cbrte2 ? Number(this.form.cbrte2) : 0) 
            + (this.form.cbrte3 ? Number(this.form.cbrte3) : 0) + (this.form.cbrte4 ? Number(this.form.cbrte4) : 0)
            + (this.form.cbrte5 ? Number(this.form.cbrte5) : 0) + (this.form.cbrte6 ? Number(this.form.cbrte6) : 0) 
            + (this.form.cbrte7 ? Number(this.form.cbrte7) : 0) + (this.form.cbrte8 ? Number(this.form.cbrte8) : 0) 
            + (this.form.cbrte9 ? Number(this.form.cbrte9) : 0) + (this.form.cbrte10 ? Number(this.form.cbrte10) : 0);
          if (totalRate > 100) {
            this.$message({
              message: "利益分配率の合計値は100以下で入力してください。",
              type: "error",
            });
            return;
          }

          this.$confirm('案件情報を最終登録します。よろしいですか？', '提示', {
              confirmButtonText: '確認',
              cancelButtonText: '戻る',
              type: 'warning'
          }).then(() => {
            var data = {
              employId: this.accountInfo.id,
              groupId: this.accountInfo.groupId,
              customerVo: {
                mcltid: this.$route.query.id,
                gropid: this.form.gropid,
                clcrpg: this.form.clcrpg,
                clclid: this.form.clclid,
                cllcid: this.form.cllcid,
                clzipc: this.form.clzipc,
                clmnkj: this.form.clmnkj,
                clmnkn: this.form.clmnkn,
                clmnid: this.form.clmnid,
                clnmkj: this.form.clnmkj,
                clnmkn: this.form.clnmkn,
                cladkj: this.form.cladkj1 + this.form.cladkj2 + " " + this.form.cladkj3,
                cladkn: this.form.cladkn,
                clbdnm: this.form.clbdnm,
                clmnpw: this.form.clmnpw,
                clmnps: this.form.clmnps,
                clmskj: this.form.clmskj,
                clmskn: this.form.clmskn,
                clcomp: this.form.clcomp,
                clickj: this.form.clickj,
                clickn: this.form.clickn,
                clcmmt: this.form.clcmmt,
                clttel: this.form.clttel,
                cltfax: this.form.cltfax,
                clicps: this.form.clicps,
                clplm1: this.form.clplm1,
                clplm2: this.form.clplm2,
                clplm3: this.form.clplm3,
                clplm4: this.form.clplm4,
                clstcd: this.form.clstcd,
                prcsst: this.form.prcsst,
              },
              payeeVo: {
                mbllid: this.form.mbllid,
                blclid: this.form.blclid,
                blcbid: this.form.blcbid,
                blnmkj: this.form.blnmkj,
                blnmkn: this.form.blnmkn,
                blzipc: this.form.blzipc,
                blakj1: this.form.blakj1,
                blakj2: this.form.blakj2,
                blltel: this.form.blltel,
                bllfax: this.form.bllfax,
                blcmmt: this.form.blcmmt,
                blstcd: this.form.blstcd,
                bltflg: this.form.bltflg,
                blpyr1: this.form.blpyr1,
                blplm1: this.form.blplm1,
                blplm7: this.form.blplm7,
                blplm8: this.form.blplm8,
                blplm9: this.form.blplm9,
                blplm10: this.form.blplm10,
              },
              contractVo: {
                tcrbid: this.form.tcrbid,
                cbotfl: this.form.cbotfl,
                cbapdt: this.form.cbapdt,
                cbcflg: this.form.cbcflg,
                cbpkbn: this.form.cbpkbn,
                cbncdy: this.form.cbncdy,
                cboccp: this.form.cboccp,
                cbsic1: this.form.cbsic1,
                cbrte1: this.form.cbrte1 ? this.form.cbrte1 : 0,
                cbsic2: this.form.cbsic2,
                cbrte2: this.form.cbrte2 ? this.form.cbrte2 : 0,
                cbsic3: this.form.cbsic3,
                cbrte3: this.form.cbrte3 ? this.form.cbrte3 : 0,
                cbsic4: this.form.cbsic4,
                cbrte4: this.form.cbrte4 ? this.form.cbrte4 : 0,
                cbsic5: this.form.cbsic5,
                cbrte5: this.form.cbrte5 ? this.form.cbrte5 : 0,
                cbsic6: this.form.cbsic6,
                cbrte6: this.form.cbrte6 ? this.form.cbrte6 : 0,
                cbsic7: this.form.cbsic7,
                cbrte7: this.form.cbrte7 ? this.form.cbrte7 : 0,
                cbsic8: this.form.cbsic8,
                cbrte8: this.form.cbrte8 ? this.form.cbrte8 : 0,
                cbsic9: this.form.cbsic9,
                cbrte9: this.form.cbrte9 ? this.form.cbrte9 : 0,
                cbsic10: this.form.cbsic10,
                cbrte10: this.form.cbrte10 ? this.form.cbrte10 : 0,
                cbgaic: this.form.cbgaic
              },
              caseDataVo: {
                id: this.form.casdid,
                corporateName: this.form.clnmkj,
                representative: this.form.clickj,
                pmtFlg: this.form.pmtFlg,
                recipientName: this.form.recipientName,
                visitDate: this.form.visitDate,
                visitTime: this.form.visitTime,
                visitChk: this.form.visitChk,
                inheritContent: this.form.inheritContent,
                paymentNote: this.form.paymentNote,
                invoiceIssue: this.form.invoiceIssue,
                pdfDate: this.form.pdfDate,
                sendDate: this.form.sendDate,
                mbPhoneNum: this.form.mbPhoneNum,
                mbMailAddress: this.form.mbMailAddress,
                interview: this.form.interview,
                expectedNum: this.form.expectedNum,
                bannerSearch1: this.form.bannerSearch1,
                bannerSearch2: this.form.bannerSearch2,
                remark: this.form.remark,
                saasCmmt: this.form.saasCmmt,
                contractorNm: this.form.clnmkj,
                mb1MailAddress: this.form.mb1MailAddress,
                mb1Name: this.form.mb1Name,
                mb1CompanyName: this.form.mb1CompanyName,
                mb2MailAddress: this.form.mb2MailAddress,
                mb2Name: this.form.mb2Name,
                mb2CompanyName: this.form.mb2CompanyName,
                mb3MailAddress: this.form.mb3MailAddress,
                mb3Name: this.form.mb3Name,
                mb3CompanyName: this.form.mb3CompanyName,
                mb4MailAddress: this.form.mb4MailAddress,
                mb4Name: this.form.mb4Name,
                mb4CompanyName: this.form.mb4CompanyName,
                gy1MailAddress: this.form.gy1MailAddress,
                gy1Name: this.form.gy1Name,
                gy2MailAddress: this.form.gy2MailAddress,
                gy2Name: this.form.gy2Name,
                gy3MailAddress: this.form.gy3MailAddress,
                gy3Name: this.form.gy3Name,
                gy4MailAddress: this.form.gy4MailAddress,
                gy4Name: this.form.gy4Name,
                appFlow: this.form.appFlow,
                classKbn: this.form.classKbn,
                jobCategory: this.form.jobCategory,
                scndAgencyChk: this.form.scndAgencyChk,
                scndAgency: this.form.scndAgency,
                scndMbCd: this.form.scndMbCd,
              },
              serviceList: this.form.serviceList,
              recruitingList: this.form.recruitingList
            };

            confirmCustomer(data).then(response => {
              this.$message({
                message: response.message,
                type: "success"
              });
              this.$router.push(ROUTER_PATH.APPLY_CENTER);
            });
          });
        } else {
          this.$message({
            message: "画面項目に入力ミスがあります。ご確認お願いいたします。",
            type: "error",
          });
          return false;
        }
      });
    },
    resetForm() {
      this.$router.push(ROUTER_PATH.APPLY_CENTER);
    }
  }
};
</script>
<style scoped>
  .sub-title {
      font-size: 16px;
      font-weight: bold;
      color: blue;
  }
  .line-name {
      text-align: right;
      vertical-align: middle;
      padding: 0 12px 0 0;
      color: #606266;
  }
  .pp-customer .el-form-item {
      margin-bottom: 20px;
  }
  .pp-service .el-form-item {
      margin-bottom: 20px;
  }
  .payee-dialog .el-row {
      margin-bottom: 0px;
  }
  .payee-name {
      margin-top: 4px;
  }
  .pp-btn {
      margin-top: 4px;
  }
  .pp-table {
      width: 95%;
      margin-left: 60px;
      margin-bottom: 20px;
  }
  .mask {
    position: absolute;
    background: white;
    top: 0px;
    right: 0px;
    width: 50%;
    height: 100%;
  }
  .mask-full {
    position: absolute;
    background: white;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
  }
</style>